import React, { useState, useEffect } from 'react'
import axios from "axios";
import { NavLink, useNavigate } from 'react-router-dom';
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';
import ipinfo from 'ipinfo';
import { PaymentProcess } from '../../components/home/process'
import { Better } from '../../components/home/better'
import FasstSection from '../../components/home/FasstSection'
import Blog from '../../components/home/BlogSection'
import DownloadApp from '../../components/home/DownloadApp'
import Hero from '../../components/home/HeroSection'
import NewsLetter from '../../components/NewsLetterSection'
import Partners from '../../components/home/partners/partners'
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Card,
    Center,
    Container,
    Flex,
    HStack,
    Heading,
    Highlight,
    Image,
    Text,
    Link,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import LinesImg from 'assets/images/lines-img.png'
import Lines2Img from 'assets/images/lines2-img.png'
import iphone from 'assets/images/iphone.png'
import ProductBg from 'assets/images/product-bg.png'
import PhoneBg from 'assets/images/phone-img.png'
import PeopleBg from 'assets/images/people.png'
import howitworks from 'assets/images/howitwork.png'
import Phone from 'assets/images/phoneone.png'
import Phonetwo from 'assets/images/phonetwo.png'
import howItsWorks from 'assets/images/howitworkpic.png'
import {
    AppleIcon,
    Bank,
    Cash,
    Dollar,
    Download,
    Fast,
    MoneyGram,
    PlayIcon,
    Safe,
    Shield,
    Wallet,
} from 'assets/icons'

import SliderImg1 from 'assets/images/sliderImg1.png'
import SliderImg4 from 'assets/images/sliderImg4.png'
import SliderImg3 from 'assets/images/sliderImg3.png'
import phoneScreen from 'assets/images/phone-screen.png'
import redLogo from 'assets/images/tago_logos/redlogo.png'
import blueLogo from 'assets/images/tago_logos/bluelogo.png'
import greenLogo from 'assets/images/tago_logos/greenlogo.png'
import purpleLogo from 'assets/images/tago_logos/purplelogo.png'
import circles from 'assets/images/circles.png'
import Mobile2 from 'assets/images/mobile2.png'
import Fasst from '../../assets/images/fast.png'
import Safes from '../../assets/images/safe.png'
import Secure from '../../assets/images/secure.png'
import { IoFlash } from "react-icons/io5";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { GrSecure } from "react-icons/gr";
import { IoMdDownload } from "react-icons/io";
import { CiBank } from "react-icons/ci";
import { SiMoneygram } from "react-icons/si";
import { BsFillWalletFill } from "react-icons/bs";
import { BsCashCoin } from "react-icons/bs";
import Lottie from "react-lottie";


import Slider from 'react-slick'
import './style.css'
import Arrow from '../../assets/images/lottieFiles/arrow.json'


const textArray = ["GLOBAL CURRENCY", "PAY ANYONE INSTANTLY !", "TRANSPARENT FEE STRUCTURE"];

export default function Landing() {
    const navigate = useNavigate();

    const handleApp = () => {
        navigate('/wallet');
    };
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0)

    const settings = {
        dots: false,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        afterChange: (index: number) => {
            setActiveSlideIndex(index)
        },
    }

    const globeOptions = {
        loop: true,
        autoplay: true,
        animationData: Arrow,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    // FUNCTION TO COLLECT VISITOR DETAILS WHEN THEY VISIT THE WEBSITE
    useEffect(() => {
        const collectVisitorDetails = async () => {
            try {
                const hasVisitorDetailsCookie = document.cookie.includes('visitorDetails=true');
                if (hasVisitorDetailsCookie) {
                    return;
                }

                const hasAcceptedCookie = document.cookie.includes('acceptedCookie=true');
                if (!hasAcceptedCookie) {
                    // If the acceptedCookie is not present, you can return or take appropriate action.
                    return;
                }

                const ipResponse = await axios.get('https://ipinfo.io?token=977e06d01e2778');

                const ipDetails = ipResponse.data;
                const timestamp = new Date();

                const details = {
                    ip: ipDetails.ip,
                    country: ipDetails.country,
                    city: ipDetails.city,
                    latitude: ipDetails.loc.split(',')[0],
                    longitude: ipDetails.loc.split(',')[1],
                    browser: navigator.userAgent,
                    device: isMobile ? 'Mobile' : isTablet ? 'Tablet' : isDesktop ? 'Desktop' : isBrowser ? 'Browser' : 'Other',
                    timestamp: timestamp.toISOString(),
                };

                // Send details to your backend for storage

                await axios.post('https://backend.tagocash.com/collectVisitorDetails', details);

                // Set a cookie to indicate that visitor details have been collected
                document.cookie = 'visitorDetails=true; max-age=' + (365 * 24 * 60 * 60); // Expires in 1 year
                console.log('Visitor details collected and sent to the backend');
            } catch (error) {
                console.error('Error collecting and sending visitor details:', error);
            }
        };

        collectVisitorDetails();
    }, []);



    const [visitorCount, setVisitorCount] = useState(0);

    useEffect(() => {
        // Fetch the visitor count from the backend

        axios.get('https://backend.tagocash.com/visitor-count')
            .then(response => {
                setVisitorCount(response.data.count);
                // Increment the visitor count on the backend
                axios.post('https://backend.tagocash.com/increment-visitor')

                    .then(() => {
                        console.log('Visitor count incremented on the backend');
                    })
                    .catch(error => {
                        console.error('Error incrementing visitor count on the backend:', error);
                    });
            })
            .catch(error => {
                console.error('Error fetching visitor count:', error);
            });
    }, []);


    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [animation, setAnimation] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            setAnimation('slide-out');
            setTimeout(() => {
                setCurrentTextIndex((prevIndex) => (prevIndex + 1) % textArray.length);
                setAnimation('slide-in');
            }, 1000); // Delay for the slide-out animation to complete
        }, 2000); // Rotate every 2 seconds (adjust as needed)

        return () => clearInterval(intervalId); // Cleanup the interval
    }, []);



    return (
        <>
            {/* START OF SECTION ONE */}
            {/* <Box as="section" minHeight={{ base: "165vh", sm: "120vh", md: "125vh", lg: "100vh" }}> */}
            <Hero />
            {/* </Box> */}


            {/* START OF SECTION TWO */}

            <Box
                as="section"
                transform="matrix(1, -0.12, 0, 1, 0, 0)"
                mt="140px"
                // position="relative"
                lineHeight={{ lg: "3.5rem" }}
                className="second_sec_text"
            >
                <div>
                    <Image
                        position="absolute"
                        display="inline"
                        src={LinesImg}
                        alt="LinesImg"
                        top={{ base: '-45px', md: '-90px' }}
                        left="0px"
                        transform="matrix(1, 0.12, 0, 1, 0, 0)"
                        w={{ base: '280px', md: 'auto' }}
                    />
                    <Box
                        background="darkBlue"
                        pt={{ base: '60px', md: '180px' }}
                        position="relative"
                        zIndex={1}
                    >
                        <Container
                            maxW="1280px"
                            transform="matrix(1, 0.12, 0, 1, 0, 0)"
                            textAlign="center"
                        >
                            <Box
                                background="radial-gradient(50% 50% at 50% 50%, #599CFF 0%, rgba(57, 184, 255, 0) 100%)"
                                opacity="0.4"
                                filter="blur(2px)"
                                transform="matrix(1, 0.12, 0, 1, 0, 0)"
                                width="85%"
                                height="95%"
                                position="absolute"
                                top="-100px"
                                left="12%"
                                zIndex="-1"
                            ></Box>

                            <Heading
                                color="primary.500"
                                mb="1.3rem"
                                fontWeight="900"
                                // fontFamily="Roboto sans-serif"
                                fontSize="4.6rem"
                            >
                                {textArray[currentTextIndex]}
                            </Heading>

                            <Flex
                                flexWrap="wrap"
                                justify="center"
                                gap="30px"
                                mt="5rem"
                                mb="5rem"
                            >
                                <SecureBox
                                    title='Tago'
                                    pro='Cash'
                                    sub=' is Fast:'
                                    icon={<IoFlash />}
                                    description='Transactions are completed in less than 10 seconds.'
                                />
                                <SecureBox
                                    title='Tago'
                                    pro='Cash'
                                    sub=' is Transparent:'
                                    icon={<AiFillSafetyCertificate />}
                                    description='You pay what you see. No hidden fees.'
                                />
                                <SecureBox
                                    title='Tago'
                                    pro='Cash'
                                    sub=' is Secure:'
                                    icon={<GrSecure />}
                                    description='Cryptographic security with biometric authentication.  Only you can access your money.'
                                />
                                <SecureBox
                                    title='Tago'
                                    pro='Cash'
                                    sub=' is Modern:'
                                    icon={<IoMdDownload />}
                                    description='Simple and easy to use'
                                />
                            </Flex>
                            {/* <Box mx="auto" textAlign="left" maxW="1280px" w="85%">

                                <Text variant="white"
                                    fontSize={{ base: "0.9rem", lg: "1.4rem" }}>
                                    <Text
                                        as="span"
                                        color="white"
                                        fontSize={{ base: "17px", lg: "27px" }}
                                        fontWeight={900}
                                    >

                                        Tago<span style={{ color: "#0581f8" }}>Cash</span> is Fast:{' '}
                                    </Text>{' '}
                                    Transactions are completed in less than 10 seconds.
                                </Text>

                                <Text variant="white"
                                    fontSize={{ base: "0.9rem", lg: "1.4rem" }}>
                                    <Text
                                        as="span"
                                        color="white"

                                        fontSize={{ base: "17px", lg: "27px" }} fontWeight={900}
                                    >
                                        Tago<span style={{ color: "#0581f8" }}>Cash</span> is Transparent:{' '}
                                    </Text>{' '}
                                    You pay what you see. No hidden fees.
                                </Text>
                                <Text variant="white"
                                    fontSize={{ base: "0.9rem", lg: "1.4rem" }}>
                                    <Text
                                        as="span"
                                        color="white"
                                        fontSize={{ base: "17px", lg: "27px" }}
                                        fontWeight={900}
                                    >
                                        Tago<span style={{ color: "#0581f8" }}>Cash</span> is Secure:{' '}
                                    </Text>{' '}
                                    Cryptographic security with biometric authentication.  Only you can access your money.
                                </Text>

                                <Text variant="white"
                                    fontSize={{ base: "0.9rem", lg: "1.4rem" }}
                                >
                                    <Text
                                        as="span"
                                        color="white"
                                        fontSize={{ base: "20px", lg: "30px" }}
                                        fontWeight={900}
                                    >
                                        Tago<span style={{ color: "#0581f8" }}>Cash</span> is Modern:{' '}
                                    </Text>{' '}
                                    Simple and easy to use
                                </Text>

                            </Box> */}
                            <Image
                                mt="40px"
                                display="inline"
                                src={PhoneBg}

                                alt="PhoneImg"
                            />

                        </Container>
                    </Box>
                </div>
            </Box>


            {/* END OF SECTION TWO */}

            {/* START OF SECTION THREE */}

            <div>
                <Box
                    as="section"
                    position="relative"
                    mt={{ base: '80px', md: '170px' }}
                >
                    <Container maxW="1280px">
                        <Flex gap="30px" justify="center" flexWrap="wrap">
                            <Card p="20px" flex="1 0 45%" minW="150px" maxW="200px">
                                <Flex align="center" justify="center" gap="15px">
                                    <Image src={blueLogo} alt="dd" width="60px" />
                                    <Text fontSize="18px" variant="black" fontWeight="900">
                                        Global.
                                    </Text>
                                </Flex>
                            </Card>
                            <Card p="20px" flex="1 0 45%" minW="150px" maxW="200px">
                                <Flex align="center" justify="center" gap="15px">
                                    <Image src={greenLogo} alt="dd" width="60px" />
                                    <Text fontSize="18px" variant="black" fontWeight="900">
                                        Secure.
                                    </Text>
                                </Flex>
                            </Card>
                            <Card p="20px" flex="1 0 45%" minW="150px" maxW="200px">
                                <Flex align="center" justify="center" gap="15px">
                                    <Image src={redLogo} alt="dd" width="60px" />
                                    <Text fontSize="18px" variant="black" fontWeight="900">
                                        Safe.
                                    </Text>
                                </Flex>
                            </Card>
                            <Card p="20px" flex="1 0 45%" minW="150px" maxW="200px">
                                <Flex align="center" justify="center" gap="15px">
                                    <Image src={purpleLogo} alt="dd" width="60px" />
                                    <Text fontSize="18px" variant="black" fontWeight="900">
                                        Transparent.
                                    </Text>
                                </Flex>
                            </Card>
                        </Flex>
                    </Container>

                    <Image
                        src={circles}
                        top="-50px"
                        position="absolute"
                        right="0"
                        h={{ base: '180px', md: 'auto' }}
                        zIndex="-1"
                    />
                </Box>

                <Box
                    as="section"
                    justifyContent="space-between"
                    alignItems={{ md: 'center', lg: "center" }}
                    display={{ md: 'block', lg: 'flex' }}
                    mt={{ base: '4rem', lg: '6rem' }}
                    paddingLeft={{ lg: "10px", xl: "40px" }}
                    paddingRight={{ lg: "10px", xl: "40px" }}
                    mx="4rem"
                    gap="1rem"
                    textAlign={{ base: 'center', lg: 'unset' }}
                    marginBottom={{ base: '50px' }}
                >
                    <Box>
                        <Text
                            fontSize={{ base: '50px', md: '60px', lg: "45px", xl: "60px" }}
                            fontWeight={900}
                            className="bold-text-style"
                            fontFamily="Roboto, sans-serif"
                            margin="0">
                            Send
                            <span style={{ color: "#2E3293", paddingLeft: "9px", paddingRight: "9px" }}>
                                Cash
                            </span>
                             To Anyone
                        </Text>
                        <Text fontSize={{ base: "15px", md: "20px", lg: "15px", xl: "20px" }} fontWeight={700} fontFamily="Roboto, sans-serif" textAlign="center">
                            Your partner receives <span style={{ color: '#0581f8' }}>EXACTLY</span> what you sent.
                        </Text>
                    </Box>
                    <Flex
                        justifyContent={{ base: "center", md: "center", lg: "flex-end" }}
                        alignItems="center"
                    >
                        <Image
                            src={Phone}
                            alt="howitworks"
                            maxW={{ base: '100px', sm: "150px", md: '250px', lg: '170px', xl: "200px" }}
                            w="100%"
                        />
                        <Box
                            style={{ display: "flex", alignItems: "center" }}
                            flexDirection="column"
                        >
                            <Box
                                maxW={{ base: "50px", sm: "100px", md: "150px", lg: "140px", xl: "180px" }}
                                maxH={{ base: "50px", sm: "100px", md: "150px", lg: "140px", xl: "180px" }}
                            >
                                <Lottie options={globeOptions} />
                            </Box>
                            <Text
                                textAlign={{ base: 'center', md: 'center', lg: "center" }}
                                fontSize={{ base: '16px', md: '20px' }}
                                fontWeight="700"
                                // marginTop={{ base: "20px", sm: "20px", md: "90px", lg: "80px" }}
                                // marginRight={{ base: '0px', md: '0px', lg: "130px", xl: "200px" }}
                                marginBottom="0px"
                                fontFamily="Roboto, sans-serif"
                            >
                                Peer-to-Peer <br /> digital <br /> currency <br /> transfer{' '}
                            </Text>
                        </Box>
                        <Image
                            src={Phonetwo}
                            alt="howitworks"
                            maxW={{ base: '100px', sm: "150px", md: '250px', lg: '170px', xl: "200px" }}
                            w="100%"
                        />
                    </Flex>
                </Box>
                {/* <Text
                    textAlign={{ base: 'center', md: 'center', lg: "right" }}
                    fontSize={{ base: '16px', md: '20px' }}
                    fontWeight="700"
                    marginTop={{ base: "20px", sm: "20px", md: "90px", lg: "80px" }}
                    marginRight={{ base: '0px', md: '0px', lg: "130px", xl: "200px" }}
                    marginBottom="40px"
                    fontFamily="Roboto, sans-serif"
                >
                    Peer-to-Peer digital currency transfer{' '}
                </Text> */}

                <Container maxW="1280px" textAlign="center">
                    <Heading
                        color="primary.500"
                        fontSize={{ base: '16px', md: '20px' }}
                        mt="1rem"
                    >
                    </Heading>
                    <Heading
                        my="20px"
                        fontSize={{ base: '26px', md: '50px' }}
                        fontFamily="Roboto, sans-serif"
                        fontWeight={900}
                    >
                        Why Use Tago<span style={{ color: "#2E3293" }}>Cash </span>as Remittance?
                    </Heading>
                    <Flex
                        flexWrap="wrap"
                        justify="center"
                        gap="30px"
                        mt="2rem"
                        mb="5rem"
                    >
                        <Experience
                            title='Fast'
                            icon={<IoFlash />}
                            description='Send money in seconds to your loved ones.'
                        />
                        <Experience
                            title='Safe'
                            icon={<GrSecure />}
                            description='Biometric authentication & cryptographic security'
                        />
                        <Experience
                            title='Free'
                            icon={<IoMdDownload />}
                            description='Free wallet to wallet transfer.'
                        />
                        <Experience
                            title='Transparent'
                            icon={<AiFillSafetyCertificate />}
                            description='Any transaction with cost is fully disclosed'
                        />
                    </Flex>
                </Container>
            </div >

            {/* END OF SECTION THREE */}

            {/* START OF SECTION FOUR*/}


            <div>
                <Box
                    as="section"
                    transform="matrix(1, -0.12, 0, 1, 0, 0)"
                    position="relative"
                    marginTop="150px"
                >
                    <Image
                        position="absolute"
                        display="inline"
                        src={Lines2Img}
                        alt="LinesImg"
                        bottom={{ base: '-40px', md: '-70px' }}
                        right="0px"
                        transform="matrix(1, 0.12, 0, 1, 0, 0)"
                        w={{ base: '280px', md: 'auto' }}
                    />
                    <Box
                        backgroundColor="darkBlue"
                        pb={{ base: '40px', md: '130px' }}
                        pt={{ base: '80px', md: '0px' }}
                        position="relative"
                        zIndex={1}
                        bg={`#0a2540 url(${ProductBg}) no-repeat`}
                        bgPosition={{ base: '100px 700px', md: '0px 200px' }}
                        bgSize={{ base: '60%', md: 'auto' }}
                    >
                        <Box
                            position="absolute"
                            left="31%"
                            top="55%"
                            width="20%"
                            transform={{ base: 'translate(50%, 50%)', sm: 'none' }}
                            css={{
                                '@media (max-width: 600px)': {
                                    left: '50%',
                                    top: '65%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '95%'
                                }
                            }}
                        >
                            <Image
                                src={PeopleBg}
                                alt=""
                                width="100%"
                                borderWidth="80px"
                                borderColor="black"
                            />
                        </Box>
                        <Container
                            maxW="1280px"
                            transform="matrix(1, 0.12, 0, 1, 0, 0)"
                            overflow="hidden"
                        >
                            <Flex
                                align="center"
                                justifyContent="space-between"
                                display={{ base: 'block', md: 'flex' }}
                                textAlign={{ base: 'center', md: 'left' }}
                            >
                                <Box maxW="482px" w="100%" mb="5rem">
                                    <Text
                                        variant="white"
                                        fontSize={{ base: '35px', md: '50px' }}
                                        my="10px"
                                        fontWeight="900"
                                        fontFamily="Roboto, sans-serif"
                                    >
                                        <Highlight query="Clean" styles={{ color: '#23a6f0' }}>Clean,
                                        </Highlight><Highlight query="Easy" styles={{ color: '#23a6f0' }}>Easy</Highlight>, and <Highlight query="Friendly" styles={{ color: '#23a6f0' }}>Friendly
                                        </Highlight> App
                                    </Text>
                                    <Text variant="white"></Text>
                                </Box>
                                <Box position="relative">
                                    <Box
                                        background="radial-gradient(50% 50% at 50% 50%, #599CFF 0%, rgba(57, 184, 255, 0) 100%)"
                                        opacity="0.4"
                                        filter="blur(2px)"
                                        transform="matrix(1, 0.02, 0, 1, 0, 0)"
                                        width="94%"
                                        height="95%"
                                        position="absolute"
                                        top="-100px"
                                        left="8%"
                                        zIndex="-1"
                                    ></Box>
                                    <Image
                                        src={phoneScreen}
                                        alt="PhoneImg"
                                        position="relative"
                                        left="10px"
                                        objectFit="contain"
                                        width={{ base: '50%', md: '330px' }}
                                    />
                                </Box>
                            </Flex>
                        </Container>
                    </Box>
                </Box>

                {/* <Container my={{ base: '40px', md: '80px' }} maxW="1280px">
                        <Flex
                            bg="#DEF2FF"
                            padding={{ base: '30px 20px 0', md: '100px 99px 0px' }}
                            borderRadius="24px"
                            direction={{ base: 'column', lg: 'row' }}
                            align="center"
                            textAlign="center"
                            mt="15rem"
                        >
                            <Box mb={{ lg: '35px' }}>
                                <Text
                                    color="primary.500"
                                    fontSize={{ base: '16px', md: '20px' }}
                                    fontWeight="400"
                                >
                                Invoice Creation
                                </Text>
                                <Heading
                                    fontSize={{ base: '26px', md: '35px' }}
                                    fontWeight={900}
                                >
                                Payment of Invoices
                                </Heading>
                                <Text mt="12px">
                                Users can create invoices and request payment directly through the app.
                                </Text>
                            </Box>
                            <Image
                                maxW={{ base: '300px', md: '580px' }}
                                w="100%"

                                src={MobileImg}

                            />
                        </Flex>
                    </Container> */}

                {/* <Box as="section" position="relative">
                        <Container mb="50px" maxW="1280px">
                            <Flex
                                gap={{ md: '66px' }}
                                flexWrap="wrap"
                                bg="#D6F3F1"
                                padding={{ base: '20px', md: '75px 99px' }}
                                borderRadius="24px"
                            >

                                <Image src={creditCard} flex={1} />

                                <Box
                                    mt={{ base: '0', md: '25px' }}
                                    flex={1}
                                    minW="280px"
                                >
                                    <Text
                                        color="primary.500"
                                        fontSize={{ base: '16px', md: '20px' }}
                                        fontWeight="400"
                                        pr={{ md: '80px' }}
                                    >
                                    Use your debit card to withdraw cash at any ATM worldwide (fees apply)
                                    </Text>
                                    <Heading
                                        mt="12px"
                                        pr={{ md: '80px' }}
                                        fontSize="26px"
                                        fontWeight={900}
                                    >
                                    Get your virtual card into your Apple Pay or your Google Pay
                                    </Heading>
                                    <HStack
                                        border="1px solid green"
                                        justify="space-around"
                                        borderRadius="5px"
                                        py="6px"
                                        my={{ base: '25px', md: '25px' }}
                                    >
                                        <ApplePay />
                                        <GooglePay />
                                        <Visa />
                                        <SamsungPay />
                                    </HStack>
                                    <Button
                                        rightIcon={<Vector color="white" />}
                                        fontSize={{ base: '14px', md: '18px' }}
                                        whiteSpace="pre-wrap"
                                        h="max-content"
                                        py="10px"
                                    >
                                        Apply for TgaCash DEBIT/CREDIT CARD 
                                    </Button>
                                </Box>
                            </Flex>
                        </Container>

                        <Image
                            src={circles}
                            top="-50px"
                            position="absolute"
                            left="0"
                            transform="rotate(-180deg)"
                            zIndex="-1"
                            display={{ base: 'none', md: 'block' }}
                        />
                    </Box> */}
            </div >
            {/* END OF SECTION FOUR */}

            <div style={{
                marginTop: '5em'
            }}>
                <FasstSection />
            </div>
            <PaymentProcess />
            <Better />


            {/* START OF SECTION FIVE */}


            <div>
                <Container maxW="1280px" mb={{ base: '40px', md: '8rem' }} mt="5em">

                    <Heading
                        fontSize={{ base: '26px', md: '50px', lg: '60px' }}
                        mb="20px"
                        fontWeight={900}
                        textAlign="center"
                        // textTransform="uppercase"
                        fontFamily="Roboto, sans-serif"
                    >
                        Get More With Tago<span style={{ color: '#2E3293' }}>Cash</span>
                    </Heading>
                    <Flex flexWrap="wrap" gap="24px" mb="30px" justify="center">
                        <WhatWeOffer
                            title='Bank Deposit & Withdrawal'
                            icon={<CiBank />}
                            description='Link bank account to your wallet for immediate deposit & withdrawl.'
                        />
                        <WhatWeOffer
                            title='Withdraw or deposit currency from 21,000+ store'
                            icon={<SiMoneygram />}
                            description='Withdraw or top up your wallet from any MoneyGram store.'
                        />


                        <WhatWeOffer
                            title='Global Availability'
                            icon={<BsFillWalletFill />}
                            description='Use any of our global partners to deposit or withdraw cash'
                        />
                        <WhatWeOffer
                            title='Bank or Mobile Money Transfer'
                            icon={<BsCashCoin />}
                            description='Withdraw or deposit cash via bank transfer or via mobile money.'
                        />

                    </Flex>
                </Container>
            </div >

            {/* END OF SECTION FIVE */}

            {/* START OF  SECTION SIX */}
            <Box
                as="section"
                transform="matrix(1, -0.12, 0, 1, 0, 0)"
                position="relative"
            >
                <div >
                    <Image
                        position="absolute"
                        display="inline"
                        src={Lines2Img}
                        alt="LinesImg"
                        bottom={{ base: '-40px', md: '-70px' }}
                        right="0px"
                        transform="matrix(1, 0.12, 0, 1, 0, 0)"
                        w={{ base: '280px', md: 'auto' }}
                    />
                    <Box
                        backgroundColor="darkBlue"
                        pb={{ base: '40px', md: '130px' }}
                        pt={{ base: '80px', md: '0px' }}
                        position="relative"
                        zIndex={1}
                        bg={`#0a2540 url(${ProductBg}) no-repeat`}
                        bgPosition={{ base: '0px 700px', md: '0px 200px' }}
                        bgSize={{ base: '60%', md: 'auto' }}
                    >
                        <Box
                            position="absolute"
                            left="35%"
                            top="40%"
                            width="20%" // Adjust the initial width value as needed
                            transform={{ base: 'translate(50%, 50%)', sm: 'none' }} // Adjust the transform values for different breakpoints
                            css={{
                                '@media (min-width: 1200px) and (max-width: 1400px)': {
                                    left: '45%',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '20%', // Adjust the width value for smaller screens
                                },
                                '@media (max-width: 960px)': {
                                    left: '50%',
                                    top: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '20%', // Adjust the width value for smaller screens
                                },
                                '@media (max-width: 767px)': {
                                    left: '50%',
                                    top: '43%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '80%', // Adjust the width value for smaller screens
                                },
                                '@media (max-width: 600px)': {
                                    left: '50%',
                                    top: '40%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '90%', // Adjust the width value for smaller screens
                                },
                                '@media (max-width: 500px)': {
                                    left: '50%',
                                    top: '37%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '90%', // Adjust the width value for smaller screens
                                },
                                '@media (max-width: 470px)': {
                                    left: '50%',
                                    top: '31%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '88%', // Adjust the width value for smaller screens
                                },
                                '@media (max-width: 460px)': {
                                    left: '54%',
                                    top: '28%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '90%', // Adjust the width value for smaller screens
                                },
                                '@media (max-width: 360px)': {
                                    left: '55%',
                                    top: '30%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '90%', // Adjust the width value for smaller screens
                                },
                            }}
                        >
                            <Image
                                src={PeopleBg}
                                alt=""
                                width="100%"
                                borderWidth="80px"
                                borderColor="black"
                            />
                        </Box>

                        <Container
                            maxW="1280px"
                            transform="matrix(1, 0.12, 0, 1, 0, 0)"
                            overflow="hidden"
                        >
                            <Flex
                                align="center"
                                justifyContent="space-between"
                                display={{ base: 'block', md: 'flex' }}
                                textAlign={{ base: 'center', md: 'left' }}
                            >
                                <Box
                                maxWidth={{sm: "100%",md:"482px"}}
                                    maxW="482px"
                                    w="100%"
                                >
                                    <Text
                                        variant="white"
                                        fontSize={{ base: '40px', md: '60px' }}
                                        my="10px"
                                        fontWeight={{ base: "700" }}
                                        fontFamily="Roboto, sans-serif"
                                    >
                                        <span style={{ color: "#23A6F0", paddingRight: "9px" }}>
                                            Cash
                                        </span>
                                        Wallet or Remittance App?
                                    </Text>
                                    <Text variant="white" fontFamily="Roboto, sans-serif">
                                        When given a choice, take both
                                    </Text>
                                </Box>
                                <Box position="relative" ml={{ base: 0, md: '40px' }}>
                                    <Box
                                        background="radial-gradient(50% 50% at 50% 50%, #599CFF 0%, rgba(57, 184, 255, 0) 100%)"
                                        opacity="0.4"
                                        filter="blur(2px)"
                                        transform="matrix(1, 0.02, 0, 1, 0, 0)"
                                        width="94%"
                                        height="95%"
                                        position="absolute"
                                        top="-100px"
                                        left="8%"
                                        zIndex="-1"
                                    ></Box>
                                    <Image
                                        mt={{ base: '0rem', md: '40px' }}
                                        src={iphone}
                                        alt="PhoneImg"
                                    />
                                </Box>
                            </Flex>
                            <HStack
                            marginTop={{md: "60px",lg:"60px"}}
                                justify="center"
                                align="flex-start"
                                flexWrap="wrap"
                                gap="25px"
                            >
                                <Services
                                    icon={<Dollar size="40" color="white" />}
                                    title='60+ Currencies'
                                    description='Instantly convert $ to 60+ currencies.'
                                    onClick={handleApp}
                                />
                                <Services
                                    icon={<Cash size="40" color="white" />}
                                    title='Easy & Free Transfers'
                                    description='Instantly send money to family, friends, or business partners.'
                                    onClick={handleApp}
                                />

                                <Services
                                    icon={<Shield size="40" color="white" />}
                                    title='Fast & Secure Services'
                                    description='Best in class security with Multisig Verification'
                                    onClick={handleApp}
                                />

                                <Services
                                    icon={<Wallet size="40" color="white" />}
                                    title='Wallet Top - Up'
                                    description='Seamlessly transfer money between accounts and wallet.'
                                    onClick={handleApp}
                                />
                            </HStack>
                        </Container>
                    </Box>
                </div >

            </Box>

            {/* END OF SECTION SIX */}

            {/* START OF SECTION SEVEN */}

            <div >
                <Box as="section" my="60px" marginTop="100px" >
                    <Container maxW="1280px">
                        <Heading
                            fontSize={{ base: '26px', md: '50px' }}
                            fontWeight="900"
                            fontFamily="Roboto, sans-serif"
                            textAlign="center"
                            textTransform="uppercase"
                            color="var(--chakra-colors-secondary)"
                            mb={{ base: '80px', md: '20px' }}
                        >
                            PAYING SOMEONE IS NOW SIMPLE, EASY AND FREE
                        </Heading>

                        <Flex
                            justify="space-between"
                            gap={{ base: '40px', xl: '136px' }}
                            display={{ base: 'block', lg: 'flex' }}
                            marginTop="2rem">

                            <Accordion
                                allowToggle
                                defaultIndex={[activeSlideIndex]}
                                index={[activeSlideIndex]}
                                flex={1}
                                mt="50px">

                                <AccordionItem
                                    border="0"
                                    borderRadius="6px"
                                    bg="white"
                                    mt={{ base: '15px', md: '30px' }}>
                                    <h2>
                                        <AccordionButton
                                            _expanded={{
                                                border: '1px solid #7782FF',
                                                borderBottom: 'none',
                                                borderRadius: '6px 6px 0 0',
                                                bg: '#7782FF0D',
                                            }}>
                                            <Box
                                                boxSize="50px"
                                                rounded="full"
                                                bg="#7782FF"
                                                color="white"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                mr="30px">
                                                1
                                            </Box>
                                            <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                                fontWeight="900"
                                            >
                                                Choose who to pay
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel
                                        pb={4}
                                        color="#636E82"
                                        fontSize="18px"
                                        border="1px solid #7782FF"
                                        borderTop="none"
                                        borderRadius="0 0 6px 6px"
                                        bg="#7782FF0D"
                                        pl="90px"
                                    >
                                        Enter their email address, their TagoID or their mobile phone number
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem
                                    border="0"
                                    borderRadius="6px"
                                    bg="white"
                                    mt={{ base: '15px', md: '30px' }}
                                >
                                    <h2>
                                        <AccordionButton
                                            _expanded={{
                                                border: '1px solid #FEBA66',
                                                borderBottom: 'none',
                                                borderRadius: '6px 6px 0 0',
                                                bg: '#7782FF0D',
                                            }}
                                        >
                                            <Box
                                                boxSize="50px"
                                                rounded="full"
                                                bg="#FEBA66"
                                                color="white"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                mr="30px"
                                            >
                                                2
                                            </Box>
                                            <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                                fontWeight="900"
                                            >
                                                Enter the amount to pay
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel
                                        pb={4}
                                        color="#636E82"
                                        fontSize="18px"
                                        border="1px solid #FEBA66"
                                        borderTop="none"
                                        borderRadius="0 0 6px 6px"
                                        bg="#7782FF0D"
                                        pl="90px"
                                    >
                                        Depending on your profile, the amount can be limited to no more than $1,000 per day. Money can be transferred from your linked Bank account to your wallet
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem
                                    border="0"
                                    borderRadius="6px"
                                    bg="white"
                                    mt={{ base: '15px', md: '30px' }}
                                >
                                    <h2>
                                        <AccordionButton
                                            _expanded={{
                                                border: '1px solid #FF5196',
                                                borderBottom: 'none',
                                                borderRadius: '6px 6px 0 0',
                                                bg: '#7782FF0D',
                                            }}
                                        >
                                            <Box
                                                boxSize="50px"
                                                rounded="full"
                                                bg="#FF5196"
                                                color="white"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                mr="30px"
                                            >
                                                3
                                            </Box>
                                            <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                                fontWeight={900}
                                            >Verify Payment Information
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel
                                        pb={4}
                                        color="#636E82"
                                        fontSize="18px"
                                        border="1px solid #FF5196"
                                        borderTop="none"
                                        borderRadius="0 0 6px 6px"
                                        bg="#7782FF0D"
                                        pl="90px"
                                    >
                                        Verify that information is correct and authenticate that it is you
                                    </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem
                                    border="0"
                                    borderRadius="6px"
                                    bg="white"
                                    mt={{ base: '15px', md: '30px' }}
                                >
                                    <h2>
                                        <AccordionButton
                                            _expanded={{
                                                border: '1px solid #0C9D7D',
                                                borderBottom: 'none',
                                                borderRadius: '6px 6px 0 0',
                                                bg: '#7782FF0D',
                                            }}
                                        >
                                            <Box
                                                boxSize="50px"
                                                rounded="full"
                                                bg="#0C9D7D"
                                                color="white"
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="center"
                                                mr="30px"
                                            >
                                                4
                                            </Box>
                                            <Box
                                                as="span"
                                                flex="1"
                                                textAlign="left"
                                                fontWeight={900}
                                            >

                                                Authorize the immeadiate payment
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel
                                        pb={4}
                                        color="#636E82"
                                        fontSize="18px"
                                        border="1px solid #0C9D7D"
                                        borderTop="none"
                                        borderRadius="0 0 6px 6px"
                                        bg="#7782FF0D"
                                        pl="90px"
                                    >
                                        Receiver receives exactly what was sent in less than 10sec
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                            <Box
                                maxH="655px"
                                maxW="650px"
                                flex={1}
                                marginTop="100px"
                            >
                                <Slider {...settings}>
                                    <Box>

                                        <Image src={howitworks} alt="dd" width={{ base: "100%", lg: "130%" }} />
                                    </Box>
                                    <Box>
                                        <Image src={howitworks} alt="dd" width={{ base: "100%", lg: "130%" }} />
                                    </Box>
                                    <Box>
                                        <Image src={howitworks} alt="dd" width={{ base: "100%", lg: "130%" }} />
                                    </Box>
                                    <Box>
                                        <Image src={howitworks} alt="dd" width={{ base: "100%", lg: "130%" }} />

                                    </Box>
                                </Slider>
                            </Box>
                        </Flex>
                    </Container>
                </Box>

                <Box bg="#0A2540" h={{ lg: '457px' }} overflow="hidden">
                    <Container maxW="1280px">
                        <Flex
                            align={{ base: 'center', md: 'flex-end' }}
                            justify="space-between"
                            textAlign={{ base: 'center', md: 'left' }}
                            direction={{ base: 'column', md: 'row' }}
                        >
                            <Box py={{ base: '50px', md: '96px' }}>
                                <Text
                                    fontSize={{ base: '16px', md: '26px' }}
                                    color="primary.500"
                                >
                                    Our Mobile App
                                </Text>
                                <Heading
                                    fontSize={{ base: '26px', md: '40px' }}
                                    fontWeight="900"
                                    color="white"
                                    mt="21px"
                                    mb="15px"
                                >
                                    Wherever You Go, <span style={{ color: '#0581f8' }}>Tago!</span>
                                </Heading>
                                <Text
                                    fontSize="18px"
                                    fontWeight={400}
                                    color="white"
                                >
                                    Tago<span style={{ color: '#0581f8' }}>Cash</span> Is Your Global Companion
                                </Text>
                                <HStack
                                    gap="16px"
                                    mt="47px"
                                    flexWrap="wrap"
                                    justify={{ base: 'center', md: 'flex-start' }}
                                    w={{ base: '100%', md: 'max-content' }}
                                >
                                    <Box w={{ base: '140px', md: '230px' }}>
                                        <NavLink to="/wallet">
                                            <PlayIcon />
                                        </NavLink>
                                    </Box>
                                    <Box w={{ base: '140px', md: '230px' }}>
                                        <NavLink to="/wallet">
                                            <AppleIcon />
                                        </NavLink>
                                    </Box>
                                </HStack>
                                <Text
                                    fontSize="18px"
                                    fontWeight={400}
                                    color="white"
                                    paddingTop='2em'
                                >
                                    Don&apos;t Leave Home Without It!
                                </Text>
                            </Box>
                            <Box position="relative" display="flex">
                                <Image
                                    position="relative"
                                    zIndex="1"
                                    src={Mobile2}
                                    alt="PhoneImg"
                                />
                                <Box
                                    background="radial-gradient(50% 50% at 50% 50%, #599CFF 0%, rgba(57, 184, 255, 0) 100%)"
                                    opacity="0.4"
                                    filter="blur(2px)"
                                    transform="matrix(1, 0.02, 0, 1, 0, 0)"
                                    width="196%"
                                    height="195%"
                                    position="absolute"
                                    top="-100px"
                                    left="-180px"
                                ></Box>
                            </Box>
                        </Flex>
                    </Container>
                </Box>

                <Box textAlign="center" py="60px">
                    <Container maxW="1280px">
                        <Text
                            color="primary.500"
                            fontSize={{ base: '16px', md: '20px' }}
                            textAlign="center"
                        >
                            Ready to get started?
                        </Text>
                        <Heading
                            fontSize={{ base: '26px', md: '40px' }}
                            my="16px"
                            fontWeight={800}
                            textAlign="center"
                        >
                            Get TagoCash And Never Pay To Send Money
                        </Heading>
                        <NavLink to="/signup">
                            <Button rounded="full" w="262px">
                                Register
                            </Button>
                        </NavLink>
                    </Container>
                </Box>
            </div >

            <DownloadApp />
            <Blog />
            <NewsLetter />
            <Partners />
            {/* END OF SECTION SEVEN */}
        </>
    )
}
function SecureBox({
    icon,
    title,
    pro,
    sub,
    description,
}: {
    icon: JSX.Element
    title: string
    pro: string
    sub: string
    description: string
}) {
    return (
        <Box
            minW="220px"
            maxW="320px"
            bg="white"
            borderRadius="20px"
            boxShadow="sm"
            paddingLeft="48px"
            paddingRight="48px"
            paddingTop="15px"
            paddingBottom="15px"
            lineHeight="1.3"
            textAlign="center"
            flex={1}
            transition="background-color 0.3s ease"
            _hover={{
                bg: '#80E9FF',
                color: "white"
            }}
        >
            <span style={{ fontSize: "40px", color: "#0581f8" }}>{icon}</span>
            <Text fontSize="25px" mt="15px" fontWeight={700} fontFamily="Roboto, sans-serif" color="#636e82">
                {title}
                <span style={{ color: "#2E3293", fontSize: "24px", fontWeight: "700" }}>
                    {pro}
                </span>
                {sub}
            </Text>
            <Text fontSize="15px" mt="10px" fontWeight={400} color="#636e82">
                {description}
            </Text>
        </Box>
    )
}

function Experience({
    icon,
    title,
    description,
}: {
    icon: JSX.Element
    title: string
    description: string
}) {
    return (
        <Box
            minW="220px"
            maxW="320px"
            bg="white"
            borderRadius="20px"
            boxShadow="sm"
            p="40px"
            textAlign="center"
            borderBottom="10px solid #80E9FF"
            flex={1}
        >
            <span style={{ fontSize: "40px", color: "#0581f8" }}>{icon}</span>
            <Text fontSize="24px" mt="15px" fontWeight={700}>
                {title}
            </Text>
            <Text fontSize="15px" mt="10px" fontWeight={400}>
                {description}
            </Text>
        </Box>
    )
}

function WhatWeOffer({
    icon,
    title,
    description,
}: {
    icon: JSX.Element
    title: string
    description: string
}) {
    return (
        <Box
            minW="294px"
            maxW="494px"
            borderRadius="12px"
            padding="32px 20px"
            textAlign="center"
            bg="#EBF8FE"
            flex={1}
            _hover={{ bg: 'white' }}
        >
            <Center
                bg="white"
                w="min-content"
                borderRadius="12px"
                width="80px"
                height='80px'
                mx="auto"
            >
                <span style={{ fontSize: "40px", color: "#0581f8" }}>{icon}</span>
            </Center>
            <Heading
                fontSize={{ base: '20px', md: '24px' }}
                mt="24px"
                mb="10px"
            >
                {title}
            </Heading>
            <Text>{description}</Text>
        </Box>
    )
}


function Services({
    icon,
    title,
    description,
    onClick,
}: {
    icon: JSX.Element
    title: string
    description: string
    onClick: () => void
}) {
    return (
        <Box textAlign={{ base: 'center', md: 'left' }} minW="200px" flex={1}>
            <Center
                bg="primary.500"
                w="min-content"
                borderRadius="4px"
                p="5px"
                mx={{ base: 'auto', md: '0' }}
                mb="25px"
                onClick={onClick}
                cursor="pointer"
            >
                {icon}
            </Center>
            <Text color="white">{title}</Text>
            <Text height='50px'>{description}</Text>
            <Button
                px="0"
                mt="18px"
                variant="ghost"
                rightIcon={<ChevronRightIcon />}
                onClick={onClick}
            >
                Get app Now
            </Button>
        </Box>
    )
}
