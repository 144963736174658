import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios"
import {
  download_section_logo,
  download_section_logo_mobile,
  google_play_cta,
  app_store_cta,
} from "../../assets/images";
import DOMPurify from 'dompurify'
import { Base_URL } from "constant/Constant";

const DownloadApp = () => {
  
  const [homePage, setHomePage] = useState({}); // Use object instead of array

  useEffect(() => {
    const fetchHomePage = async () => {
      try {
        const res = await axios.get(`${Base_URL}/home_page`);
        setHomePage(res.data[0]); // Assuming you're expecting one item
      } catch (err) {
        console.log(err);
      }
    };
    fetchHomePage();
  }, []);

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }

  return (
    <>
    <div className="d-none d-md-block download_app_section position-relative mt-5">
      <div className="position-absolute end-0 pe-4">
        <img
          src={download_section_logo}
          height={"180px"}
          width={"408px"}
          alt=""
        />
      </div>
      <div className="d-flex gap-3 app_link_position">
        <NavLink className="text-decoration-none" to="/wallet">
          <img src={google_play_cta} width={"166px"} height={"47px"} alt="" />
        </NavLink>
        <NavLink className="text-decoration-none" to="/wallet">
          <img src={app_store_cta} width={"153px"} height={"47px"} alt="" />
        </NavLink>
      </div>
      <div className="container">
        <div className="d-flex align-items-center">
          <div className="mt-5 ms-3">
            <h3 className="sub_title first_to_know_color" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc16) }} />
            <p className="download_app_subtext first_to_know_color" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par13) }} />
          </div>
        </div>
      </div>
    </div>

    {/*begin mobile */}
    <div className="d-block d-md-none download_app_section mt-5 position-relative">
      <img
        className="position-absolute bottom-0 start-50 translate-middle-x"
        src={download_section_logo_mobile}
        height={"120px"}
        width={"303px"}
        alt=""
      />
      <div className="container">
        <div className="d-flex align-items-center">
          <div className="text-center mx-auto">
            <h3 className="sub_title first_to_know_color mt-4" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc16) }} />
            <p className="download_app_subtext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par13) }} />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center gap-3 mt-4 position-relative">
        <NavLink className="text-decoration-none" to="/wallet">
          <img src={google_play_cta} width={"166px"} height={"47px"} alt="" />
        </NavLink>
        <NavLink className="text-decoration-none" to="/wallet">
          <img src={app_store_cta} width={"166px"} height={"47px"} alt="" />
        </NavLink>
      </div>
    </div>
    {/*end mobile */}
  </>
);
};

export default DownloadApp;
