import React, { useEffect, useState } from "react";
import {
  app_store_cta,
  google_play_cta,
  currencyanimation,
} from "../../assets/images";
import { NavLink } from "react-router-dom";

import {
  AppleIcon,
  PlayIcon,
} from '../../assets/icons'
import Lottie from "react-lottie";
import heroLottie from "../../assets/images/lottieFiles/hero.json";
import Coin from "../../assets/images/coin.mp4";
import Pexels from "../../assets/images/pexels.mp4";
import Counting from "../../assets/images/counting.mp4";
import axios from "axios"
import DOMPurify from 'dompurify'


const HeroSection = () => {

  const globeOptions = {
    loop: true,
    autoplay: true,
    animationData: heroLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent
  }

  const [homePage, setHomePage] = useState({}); // Use object instead of array

  useEffect(() => {
    const fetchHomePage = async () => {
      try {
        const res = await axios.get('https://backend.tagocash.com/home_page');
        setHomePage(res.data[0]); // Assuming you're expecting one item
      } catch (err) {
        console.log(err);
      }
    };
    fetchHomePage();
  }, []);


  return (
    <div className="container mt-5 mb-5">
      <div className="row">
        <div className="col-lg-8 heros_cont">
          <h1
            style={{
              fontWeight: "900",
              fontSize: "4.6rem",
              lineHeight: '1.6em'
            }}
            className="hero_title text-center text-md-start heading-anim hero-header-title m-0"
          >
            <strong>Wherever You Go, <span style={{ color: "#2E3293" }}>Tago!</span></strong>
          </h1>
          <div className="hero_subtitle subtitle_small text-center text-md-start m-0" >
            <h3
              className="hero_subtitle subtitle_small m-0"
              style={{
                fontWeight: "900",
                lineHeight: '2em',
              }}
            >
              Tago<span style={{ color: "#2E3293" }}>Cash</span> is Your Global Companion
            </h3>
            <span
              className="sub_text hero_subtext sub-title-style"
            >
              Don&apos;t Leave Home Without it !
            </span>
          </div>
          <div className="d-flex gap-5 mt-5">
            <NavLink className="text-decoration-none google_play" to="/wallet">
              <img
                src={google_play_cta}
                width={"200px"}
                height={"80px"}
                alt={`Blog Cover image`}
                className="google_play"
              />
            </NavLink>
            <NavLink className="text-decoration-none" to="/wallet">
              <img
                src={app_store_cta}
                width={"190px"}
                height={"80px"}
                alt={`Blog Cover image`}
                className="google_play"
              />
            </NavLink>
          </div>

          <div className="align-items-center mt-5">
            <h3
              className="sub_title globally_title safe_width text-center text-md-start">
              The Safest, Easiest & Most <br /> Secure Way to Hold Money
            </h3>
          </div>
        </div>
        <div className="col-lg-4 heros_div">
          <div className="hero_animation">
            <Lottie options={globeOptions} />
          </div>
          <div className="currency_animation_block mt-5">
            <img
              src={currencyanimation}
              style={{height: "55px"}}
              alt="currencies"
              className="currencyanimation_image_size"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;

