import React from 'react';
import './policy.css'; // Assuming you have a CSS file for styling

const Privacy = () => {
    return (
        <div className="privacy-container">
        
            
            <div className="top-section">
                <h1>Privacy Policy</h1>
                <p>Last updated: January 16, 2024.</p>
            </div>
            <div className="privacy-container-wrapper">
            <div className="policy-details">
                <h1>1. Introduction</h1>
                <p>TagoBits, TagoCash.com, TagoPay, TagoCash, TagoCash Wallet Apps, Tago (collectively, &quot;TagoCash&quot;) refer to the TagoCash websites and TagoCash wallet apps. These Terms of Service, together with TagoBits&apos;s Privacy Statement (collectively the &quot;`Terms&quot;`), govern your use of the services offered therein (collectively, the &quot;`Sites&quot;`). Your access to these services is in a way evidence of your acceptance of the Terms and your agreement to the enforcement of said Terms. If you do not agree to the Terms, you may not use the Sites. TagoBits reserves the right, in its sole discretion, to change, modify, or otherwise alter the Terms at any time, effective upon posting of the modified Terms on the Sites. Your continued use of the Sites or any materials or services accessible through the Sites after such posting means you accept the modifications.
<br />
<br />
TagoBits, Inc.  is a United State Company, registered in the great state of Wyoming.  TagoCash is a product of TagoBits, Inc.  TagoCash operates globally via its web site or its mobile apps.  As a product, TagoCash is a peer-to-peer digital wallet that operates in a closed-loop environment. Within the closed loop platform, users can send and receive the platform default digital currency without fee.  In order to facilitate user on and off ramping, TagoCash leverages local anchors (banks, financial institutions, etc) who convert users fiat currency to TagoCash platform default digital currency.  For that reason, TagoCash leverages local and international outside services for Compliance with KYC and AML regulations.  It fully  complies with European and United States regulations.
<br />
<br />
This is our Privacy Statement. In this document we explain what kind of personal data we collect via our Services. Personal data means all information by which a person can be directly or indirectly identified and is a term under the General Data Protection Regulation <strong>(GDPR)</strong>. We also explain what role we have in the processing of personal data, how long we retain personal data and what rights you have as a data subject.
<br />
<br />
Please read this Privacy Statement carefully. Please note that this Privacy Statement utilizes certain terms that are defined in the General Terms and Conditions TagoCash.
  </p>

                <h1>2. Who is responsible for the processing of your personal data?</h1>
                
                <p>TagoCash is responsible for the processing of your personal data as described in this Privacy Statement and acts as ‘controller’ under the GDPR, except when the processing of personal data takes places in the context of Third Party Service Provider(s) (as further explained in the General Terms and Conditions). In such cases, the Third Party Service Provider qualifies as the controller.
If you have questions about the processing of your personal data, you can always contact us through the contact details listed at the bottom of this Privacy Statement.

                    </p>

                    <h1>3. Which personal data could be processed and from which source?</h1>
                   
<h4>TagoCash may process your personal data if you:</h4>
<ol className="custom-list">
    <li>visit our Website;</li>
    <li>use our App; and/or</li>
    <li>use our Services.</li>
</ol>

<h1>3.1. Information we collect from you automatically</h1>
<p>We receive and store certain types of information automatically when you interact with the TagoCash Platform or use the Services. This information helps us address customer support issues, provide you with a streamlined and personalized experience, improve the performance of the TagoCash Platform, and protect your account from fraud by detecting unauthorized access.
    <br />
    <br />
    Information collected automatically includes:
</p>
<ol className="custom-list">
    <li><strong>Online Identifiers:</strong> Information on how our Services are accessed and used, such as your IP-address.</li>
    <li><strong>Usage Data</strong> When and for how long you visit the TagoCash Platform, which pages you visit on the TagoCash Platform, on which links you click and technical information (e.g. type of browser and operating system).</li>
</ol>

<h1>3.2. Information you provide to us</h1>
<p>To open an Account and allow you access to our Services, we will ask you to provide us with some important information about you. This information is either required to verify your identity or necessary to provide the requested Services.
    <br />
    <br />
    Below you will find an overview of the personal data we generally collect from our (potential) Users
</p>
<ol className="custom-list">
    <li><strong>Registration data:</strong> username, full name, email address, password.</li>
    <li><strong>KYC data (local third party)</strong>  Picture, government issued-ID, fingerprint</li>
    <li><strong>Account information:</strong>  Recovery Key A.</li>
</ol>
<p>In case we add new features and Services and/or applicable laws and regulations change, you may be asked to provide additional information. In such case, we will inform you about the purposes and legal grounds for such additional processing separately and/or via an update of this Privacy Statement.</p>

<h1>3.3. Profile picture data</h1>
<p>To enhance the user experience and facilitate user identification, we may ask you to provide a profile picture when you use our App. This is entirely optional and is not required to use our Services.
    <br />
    <br />
    When you choose to upload a profile picture, we will collect and process this image data. Your profile picture will be visible to other users of our Services, and it will be associated with your account activity
    <br />
    <br />
    We use your profile picture solely to provide and enhance our Services. We do not use this data for any other purposes, and we do not share it with any third parties without your explicit consent, except as required by law or as described in our privacy policy.
    <br />
    <br />
    You can change or remove your profile picture at any time through the settings in our App. If you remove your profile picture, we will delete it from our servers and it will no longer be associated with your account.
    <br />
    <br />
    Please remember that any image data you provide as a profile picture should not include sensitive information, and you should have the rights to any image you upload.
    <br/>
    <br/>
    By uploading a profile picture, you consent to the collection, use, and disclosure of this image data as described in this privacy policy.
</p>
<h1>4. For what purpose are your personal data processed?</h1>
<h4>We may use your personal data for the following purposes:</h4>
<ol className="custom-list">
    <li>To provide the Services; We process your personal data to provide the Services to you. For example, when you want to store Digital Assets in your Account, we require certain information such as your username, email address and phone number. We cannot provide you with Services without such information.</li>
    <li>To provide service communications; We send administrative or account-related information to you to keep you updated about our Services, inform you of relevant security issues or updates, or provide other transaction-related information. Without such communications, you may not be aware of important developments relating to your Account that may affect how you can use our Services. You may not opt-out of receiving critical service communications, such as emails or mobile notifications sent for legal or security purposes.
</li>
    <li>To provide customer service; We process your personal data when you contact us to resolve any questions or to troubleshoot problems. Without processing your personal data for such purposes, we cannot respond to your requests and ensure your uninterrupted use of the Services.</li>
</ol>
<h1>5. Legal Bases for Processing your information</h1>
<p>TagoCash only processes your personal data for a specified purpose and based on a legal ground, such as your consent or when necessary for the execution of the User Agreement with you, to comply with a legal obligation or to protect a legitimate interest.
</p>
<h4>Legal Bases for Processing</h4>
<p>To provide TagoCash’ Services: 
    <br />
Based on our contract with you or to take steps at your request prior to entering into a contract 
<br />
<br />
To provide service communications: <br />
Based on our contract with you or to take steps at your request prior to entering into a contract
<br />
<br />
To provide customer service:<br />
Based on our contract with you or to take steps at your request prior to entering into a contract
<br />
<br />
If you provided TagoCash your consent for any processing of your personal data, you always have the right to withdraw your consent at any moment by emailing us at:<a href='mailto:legal@tagocash.com'>legal@TagoCash.com</a>
 Please note, withdrawal of your consent does not affect the lawfulness of the processing of your personal data before such withdrawal.
</p>
<h1>6. Who may receive your personal data?</h1>
<p>TagoCash only provides access to your personal data to TagoCash staff members that need to have access to your personal data to perform their tasks and duties. TagoCash ensures that staff members are contractually or legally required to keep your personal data confidential.
    <br />
    <br />
    TagoCash only provides access to your personal data to TagoCash staff members that need to have access to your personal data to perform their tasks and duties. TagoCash ensures that staff members are contractually or legally required to keep your personal data confidential.
    <br/>
    <br/>
    In such case, users will receive a warning stating that they may choose to be linked to a Third Party Service Provider, that TagoCash does not collect or send personal data in relation to that service and from then on the Third Party Service Provider is responsible for the data and privacy of the user.
</p>
<h1>7. Does TagoCash process Europeans personal data to recipients outside the European Economic Area?</h1>
<p>TagoCash does not process European personal data to recipients outside the European Economic Area</p>
<h1>8. Your rights</h1>
<p>If your personal data is processed, you have privacy rights and, of course, TagoCash respects these rights. More specifically, you have the right of access, deletion and rectification of personal data, objection to processing of personal data, restriction of processing of personal data and the right of data portability. You can exercise your right by contacting legal@tagocahs.com  We may ask you to provide further information in order to determine your identity first or to ensure that no one else is trying to execute your privacy rights.
    <br />
    <br />
    TagoCash will respond to your request as quickly as possible, although this can take up to one month. If more time is required to complete your request, TagoCash will let you know how much longer is needed and the reasons for the delay.
    <br />
    <br />
    The above rights are not always absolute, and sometimes we may have pressing interests or a legal obligation to deny your request. In such case, we will explain to you our reason for denying your request.
</p>
<h1>9. Complaints</h1>
<p>If you believe that TagoCash has processed your personal data unlawfully or if you are not satisfied with TagoCash’ response to your request, you can send your complaint to TagoCashapp.com. TagoCash will respond to your complaint as quickly as possible.
    <br />
    <br />
    You are always entitled to file a complaint with a data protection supervisory authority if you believe that we are not processing your personal data in accordance with the GDPR. 
</p>
<h1>10. How will TagoCash protect your personal data?</h1>
<p>TagoCash will not keep your personal data longer than the mandatory statutory retention period or, if such a mandatory statutory retention period does not apply, no longer than is strictly necessary to achieve the purposes for which your personal data were collected or processed.
    <br />
    <br />
    TagoCash retains personal data processed to execute the User Agreement with you as long as the term of this agreement. TagoCash has asked for your (explicit) consent for any processing of your personal data, TagoCash retains your personal data until you withdraw your (explicit) consent or until your (explicit) consent can be deemed expired and you have not renewed such (explicit) consent.
</p>

<h1>11. Updating the Privacy Statement</h1>
<p>TagoCash may update this Privacy Statement. Any update of the Privacy Statement will apply after announcing the update on our Website, our App, or any other official communication channel. If the change to the Privacy Statement concerns a fundamental change to the nature of the processing (e.g. a new category of sensitive data processed) or if the change may be relevant to and impact upon you, TagoCash will inform you of changes to the Privacy Statement, explicitly and effectively, well in advance of the change actually taking effect.</p>
<h1>12. Contact and questions about this Privacy Statement</h1>
<p>If you want to know more about TagoCash’ Privacy Statement or have any questions or recommendations, please contact TagoCash.  TagoCash will respond to your request as quickly as possible.</p>

<p>TagoCash Contact details: <a href='mailto:legal@tagocash.com'>legal@tagocash.com</a></p>
            </div>
        </div>
        </div>
    );
};

export default Privacy;
