export default {
    fonts: {
        body: 'Nexa',
        heading: 'Nexa',
        label: 'Nexa',
    },
    colors: {
        primary: {
            50: '#23A6F0',
            100: '#23A6F0',
            200: '#23A6F0',
            300: '#23A6F0',
            400: '#23A6F0',
            500: '#23A6F0',
            600: '#23A6F0',
            700: '#23A6F0',
            800: '#23A6F0',
            900: '#23A6F0',
        },
        secondary: '#313F5B',
        text: '#636E82',
        background: '#F5FCFF',
        error: '#E84A4A',
        darkBlue: '#0A2540',
        success: {
            100: 'rgba(133, 203, 51, 0.2)',
            400: '#85CB33',
            500: '#85CB33',
            800: '#85CB33',
        },
        yellow: {
            100: 'rgba(255, 184, 49, 0.2)',
            400: '#FFB831',
            500: '#FFB831',
            800: '#FFB831',
        },
    },
    components: {
        Text: {
            baseStyle: { color: 'text', fontSize: ['14px', '16px'] },

            variants: {
                white: { color: 'white' },
                black: { color: 'black' },
                primary: { color: 'secondary' },
            },
        },
        Card: {
            baseStyle: {
                container: {
                    boxShadow: '0px 0px 20px rgba(99, 66, 128, 0.1)',
                    border: '1px solid #F2F2F2',
                    borderRadius: '10px',
                },
            },
        },
        Heading: {
            baseStyle: { color: 'secondary' },
        },
        Button: {
            baseStyle: {
                fontWeight: '500',
                border: '1px solid transparent',
            },
            variants: {
                outline: {
                    border: '1px solid #7B7382',
                    color: '#7B7382',
                    _hover: {
                        bg: 'primary.500',
                        color: 'white',
                    },
                },
                solid: {
                    _hover: {
                        bg: 'transparent',
                        borderColor: 'primary.500',
                        color: 'primary.500',
                    },
                },
                ghost: {
                    _hover: {
                        bg: 'transparent',
                    },
                },
            },
        },
    },
    styles: {
        global: {
            body: {
                bg: 'background',
                fontWeight: '400',
            },
        },
    },
}
