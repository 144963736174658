import React from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import pnfLottie from "../../../assets/images/lottieFiles/pnf.json";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  const pnfOptions = {
    loop: true,
    autoplay: true,
    animationData: pnfLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="container">
      <div className="d-flex flex-column align-items-center py-5">
        <div id="pnf_lottie">
          <Lottie options={pnfOptions} height={400} width={747} />
        </div>
        <h3 className="hero_title mb-2">Page Not Found</h3>
        <button onClick={handleGoHome} className="btn btn-primary mt-3">
          Go Back Home
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
