import React from 'react';
import './policy.css'; // Assuming you have a CSS file for styling

const Fees = () => {
    return (
        <div className="privacy-container">
            <div className="top-section">
                <h1>Sample disclosure of fees</h1>
            </div>
            <div className="privacy-container-wrapper">
            <div className="policy-details">
                <h4>Subject to periodic changes</h4>
                <br />
                <p>Many of the fees are external partner fees such as Visa, MasterCard, Bank wire fee or Forex Currency exchange fees.  TagoCash service fees are clearly labeled on the right. </p>
            </div>
                <table className="fees-table">
                    <thead>
                        <tr>
                            <th>Activity</th>
                            <th>Fee</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Registering with TagoCash</td>
                            <td>Free</td>
                        </tr>
                        <tr>
                            <td>Opening an account</td>
                            <td>Free</td>
                        </tr>
                        <tr>
                            <td>Sending money</td>
                            <td>Free</td>
                        </tr>
                        <tr>
                            <td>Uploading Money to your TagoCash (Top Up)</td>
                            <td>From Bank/Card fees 2% - 4%</td>
                        </tr>
                        <tr>
                            <td>From Bank Account (bank fees &lt;1%)</td>
                            <td>Free</td>
                        </tr>
                        <tr>
                            <td>From MoneyGram</td>
                            <td>Free</td>
                        </tr>
                        <tr>
                            <td>Converting Currency From USD to Other</td>
                            <td>1%</td>
                        </tr>
                        <tr>
                            <td>Converting Currency From Other to USD</td>
                            <td>1%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Fees;
