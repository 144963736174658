import React from 'react';
import { ChakraProvider, withDefaultColorScheme, extendTheme } from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Landing from 'views/landing';
import Navbar from 'components/header'
import Footer from 'components/footer'
import TagoWallet from 'views/pages/tagowallet';
import SignUp from 'views/pages/signup';
import FaqPage from 'views/pages/faq';
import ContactUs from 'views/pages/contact';
import PageNotFound from "views/pages/404/PageNotFound";
import BlogContent from 'views/pages/blog/BlogContent';
import Blogs from 'views/pages/blog';
import customTheme from 'assets/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Privacy from './views/pages/terms/privacy';
import Terms from './views/pages/terms/terms';
import Fees from './views/pages/terms/fees';
import ThankYou from './views/pages/thankyou/thankyou';

const theme = extendTheme(
  customTheme,
  withDefaultColorScheme({
    colorScheme: 'primary',
  })
);

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/wallet" element={<TagoWallet />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blogcontent/:id" element={<BlogContent />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/fees" element={<Fees />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path={"/*"} element={<PageNotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
