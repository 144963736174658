import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import DOMPurify from "dompurify";
import blue_background from "../../../assets/images/blue_background.png";
import Lottie from "react-lottie";
import walletLottie from "../../../assets/images/lottieFiles/wallet.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countries from 'i18n-iso-countries';
import ReactCountryFlag from 'react-country-flag';
import { useNavigate } from "react-router-dom";
import en from 'i18n-iso-countries/langs/en.json';
import Registration from '../../../assets/images/lottieFiles/Registration.json'
import { Base_URL } from "constant/Constant";
countries.registerLocale(en);


const countryList = Object.entries(countries.getNames('en')).map(([code, name]) => ({ code, name }));

const TagoWallet = () => {
  const navigate = useNavigate();

  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  const [tagoWalletPage, setTagoWalletPage] = useState({});
  useEffect(() => {
    const fetchTagoWalletPage = async () => {
      try {
        const res = await axios.get(`${Base_URL}/tagowallet_page`);
        setTagoWalletPage(res.data[0]); // Assuming you're expecting one item
      } catch (err) {
        console.log(err);
      }
    };
    fetchTagoWalletPage();
  }, []);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState('');
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formKey, setFormKey] = useState(0);

  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");

  useEffect(() => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        const country = response.data.country.toLowerCase();
        setCountry(country);
      })
      .catch((error) => {
        console.error("Error fetching user's country", error);
      });
  }, []);



  const handleFirstNameChange = (event) => {
    const newFirstName = event.target.value;
    setFirstName(newFirstName);
    setIsNameValid(newFirstName.trim() !== "");
    setNameError(newFirstName.trim() !== "" ? "" : "First Name cannot be empty");
  };

  const handleMiddleNameChange = (event) => {
    const newMiddleName = event.target.value;
    setMiddleName(newMiddleName);
    setIsNameValid(newMiddleName.trim() !== "");
    setNameError(newMiddleName.trim() !== "" ? "" : "Middle Name cannot be empty");
  };

  const handleLastNameChange = (event) => {
    const newLastName = event.target.value;
    setLastName(newLastName);
    setIsNameValid(newLastName.trim() !== "");
    setNameError(newLastName.trim() !== "" ? "" : "Last Name cannot be empty");
  };


  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
    setEmailError(emailRegex.test(newEmail) ? "" : "Invalid email format");
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const fullName = `${firstName} ${middleName} ${lastName}`;

    setIsNameValid(fullName.trim() !== "");
    setIsEmailValid(email.trim() !== "");
    setIsPhoneNumberValid(phoneNumber && phoneNumber.trim() !== "");

    setNameError(fullName.trim() !== "" ? "" : "Name cannot be empty");
    setEmailError(email.trim() !== "" ? "" : "Email cannot be empty");
    setPhoneNumberError(
      phoneNumber && phoneNumber.trim() !== "" ? "" : "Phone cannot be empty"
    );

    if (fullName.trim() !== "" && isEmailValid && isPhoneNumberValid) {
      console.log("Form submitted:", { name: fullName, email, phoneNumber });

      setFirstName("");
      setMiddleName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");

      setFormKey((prevKey) => prevKey + 1);

      // Submit data to backend
      const data = { firstname: firstName, middlename: middleName, lastname: lastName, email, country, phone: phoneNumber };
      axios
        .post(`${Base_URL}/subscribe`, data)
        .then((res) => {
          // Handle success
          console.log(res.data);
          navigate("/thankyou"); // Navigate to "/thankyou" page
        })
        .catch((err) => {
          // Handle error
          console.error(err);
        });
    }
  };



  const walletOptions = {
    loop: true,
    autoplay: true,
    animationData: Registration,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="newsletter_page position-relative">
      <div className="d-block d-md-none blue_background">
        <img
          className="position-absolute top-0 left-0"
          src={blue_background}
          width={"85%"}
          height={"275px"}
          alt=""
        />
      </div>
      <div className="container position-relative pb-5">
        <a href="/" className="text-decoration-none text-black">
          <p className="d-block d-md-none pt-4">&lt; Back</p>
        </a>
        <div className="d-md-flex py-md-5">
          <div className="img_block margin_top pb-md-5">
            <div className="d-md-block d-none">
              <Lottie options={walletOptions} height={375} width={425} />
            </div>
          </div>
          <div className="subscribe_block margin_top pb-5">
            <div className="ms-3 mt-3">
              <h3
                className="hero_title contact_title" style={{fontSize: "50px"}}
              >
                Tago<span style={{ color: "#2E3293" }}>Cash </span>
                Pre-Registration
              </h3>
              <p
                className="sub_text contact_subtext mb-3" style={{ fontFamily: "var(--chakra-fonts-body)", fontWeight: "400" }}
              >
                Be one of the first 100 users to get a bonous $10.00 in your wallet.
              </p>
            </div>
            <div className="my-4">
              <input
                type="text"
                value={firstName}
                onChange={handleFirstNameChange}
                className="form-control rounded-5 px-3 input_bg py-2 bg-secondary bg-opacity-10 border-0"
                id="nameFormControlInput"
                placeholder="First Name *"
              />
              {!isNameValid && (
                <p className="text-danger pt-2">{nameError}</p>
              )}
            </div>
            <div className="my-4">
              <input
                type="text"
                value={middleName}
                onChange={handleMiddleNameChange}
                className="form-control rounded-5 px-3 input_bg py-2 bg-secondary bg-opacity-10 border-0"
                id="nameFormControlInput1"
                placeholder="Middle Name *"
              />
              {!isNameValid && (
                <p className="text-danger pt-2">{nameError}</p>
              )}
            </div>
            <div className="my-4">
              <input
                type="text"
                value={lastName}
                onChange={handleLastNameChange}
                className="form-control rounded-5 px-3 input_bg py-2 bg-secondary bg-opacity-10 border-0"
                id="nameFormControlInput2"
                placeholder="Last Name *"
              />
              {!isNameValid && (
                <p className="text-danger pt-2">{nameError}</p>
              )}
            </div>
            <div className="mb-4">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                className="form-control rounded-5 bg-secondary bg-opacity-10 px-3 py-2 border-0"
                id="emailFormControlInput"
                placeholder="Enter Email *"
              />
              {!isEmailValid && (
                <p className="text-danger pt-2">{emailError}</p>
              )}
            </div>
            <div className='my-4'>
              <select
                className="form-select rounded-5 bg-secondary bg-opacity-10 px-3 py-2 border-0"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Select Country</option>
                {countryList.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} <ReactCountryFlag countryCode={country.code} svg />
                  </option>
                ))}
              </select>

            </div>
            <div className="mb-4">
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={handlePhoneChange}
                placeholder="Phone number"
                className="wallet_input_bg"
              />

              {!isPhoneNumberValid && (
                <p className="text-danger pt-2">{phoneNumberError}</p>
              )}
            </div>
            <div className="mt-4">
              <button
                type="button"
                onClick={handleSubmit}
                disabled={!isNameValid || !isEmailValid || !isPhoneNumberValid}
                className="btn button_color text-white rounded-5 px-5 py-2 md-5"
              >
                Subscribe
              </button>
            </div>
            <p className="text-muted pt-4 m-0">
            <span style={{ color: "#0581F8", fontWeight: "800" }}>NOTE: </span> By Subscribing, you agree to the <NavLink to='/terms'>Terms of Service</NavLink>  and attest that you are 18 Years Old or More.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagoWallet;
