import React from 'react';
import './policy.css'; // Assuming you have a CSS file for styling

const Terms = () => {
    return (
        <div className="privacy-container">
        
            
            <div className="top-section">
                <h1>Terms of Service</h1>
                <p>Last updated: November 10, 2023.</p>
            </div>
            <div className="privacy-container-wrapper">
            <div className="policy-details">
                <h1>Terms of Service And Conditions for Using TagoCash Services</h1>
                <p>TagoBits, TagoCash.com, TagoPay, TagoCash, TagoCash Wallet Apps, Tago (collectively, &quot;`TagoBits&quot;`) refer to the TagoCash websites and TagoCash wallet apps. These Terms of Service, together with TagoBits`&apos;s Privacy Statement (collectively the &quot;`Terms&quot;`), govern your use of the services offered therein (collectively, the &quot;`Sites&quot;`). Your access to these services in any way is evidence of your acceptance of the Terms and your agreement to the enforcement of said Terms. If you do not agree to the Terms, you may not use the Sites. TagoBits reserves the right, in its sole discretion, to change, modify, or otherwise alter the Terms at any time, effective upon posting of the modified Terms on the Sites. Your continued use of the Sites or any materials or services accessible through the Sites after such posting means you accept the modifications.</p>

                <h1>Site Access and Limited License of Personal Use</h1>
                <p>TagoBits, Inc., the owner of all rights in and to the Sites and the services offered therein, grants you a limited license to access and make personal use of the Sites. You may not download or modify any portion of the Sites, except with the express written consent of TagoBits. Your limited license to use the Sites does not include any resale or commercial use of the Sites or their contents, or any derivative use of the Sites or their contents, or any use of data mining, robots or similar data gathering and extraction tools or processes on the Sites. You agree not to store in any form, distribute, transmit, display, reproduce, modify, create derivative works from, sell or otherwise exploit any of the content on the Sites for any commercial purpose. For purposes of these Terms, such &quot;content&quot; shall include, without limitation, any text, software, scripts, graphics, photos, audio, music, videos, and/or interactive features accessible on or through the Sites, including all User Posts (defined below). By using the Sites, you warrant to TagoBits that you will not use the Sites, or any of the content obtained from the Sites, for any purpose that is unlawful or prohibited by the Terms. TagoBits does not grant any license or other authorization to use any of its trademarks, service marks, or other copyrightable material or other intellectual property on the Sites. If you violate any of these Terms, your permission to use the Sites automatically terminates, and TagoBits may take legal action against you to protect its intellectual property rights.
                    <br />
                    <br />
                    In consideration of your use of the Sites, you agree to: (a) provide true, accurate, current and complete information about your identity as prompted by the Sites, and (b) to maintain and update this information to keep it true, accurate, current and complete. If any information provided by you is inaccurate, not current or incomplete, TagoBits may terminate your account and refuse your future use of the Sites.</p>

                    <h1>User Conduct</h1>
                    <h4>By using the Sites you agree that you will not do any of the following:</h4>
                    
                    <ol className="custom-list">
    <li>Restrict or inhibit any other user from using and enjoying the Sites;</li>
    <li>Post or transmit any unlawful, fraudulent, libelous, defamatory, obscene, pornographic, profane, threatening, abusive, hateful, offensive, or otherwise objectionable information of any kind, including without limitation any transmissions constituting or encouraging conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any local, state, national or foreign law;
</li>
    <li>Post or transmit any advertisements, solicitations, chain letters, pyramid schemes, investment opportunities or schemes or other unsolicited commercial communication or engage in spamming or flooding; </li>
    <li>Post or transmit any information or software which contains a virus, trojan horse, worm or other harmful component;
</li>
    <li>Post, publish, transmit, reproduce, distribute or in any way exploit any information or material obtained through the Sites for commercial purposes (other than as expressly permitted by TagoBits);</li>
    <li>Upload, post, publish, transmit, reproduce, or distribute in any way, any material obtained through the Sites which is protected by copyright, or other proprietary right, or derivative works with respect thereto, without obtaining permission of the copyright owner or right holder;
</li>
<li>Take any action that imposes, or may impose in TagoBits`&apos;s sole discretion an unreasonable or disproportionately large load on TagoBits`&apos;s infrastructure;
</li>
<li>Bypass any measures TagoBits may use to prevent or restrict access to the Sites;
</li>
<li>Harvest or collect information, including screen names, about other members or users of the Sites. The use of any information learned through the Services is limited to the express purposes set forth in these Terms; all other uses, including, without limitation, sending unsolicited bulk e-mail, are strictly prohibited.</li>
<li>Take any action or upload, download, post, submit or otherwise distribute or facilitate distribution of any content using the Sites that infringes any patent, trademark, trade secret, copyright, right of publicity or other right of any other person or entity.</li>
<li>Interfere or attempt to interfere with the proper working of the Sites or any activities conducted on or through the Sites;</li>
</ol>
<br />
<p>TagoBits has no obligation to monitor the Sites. However, you acknowledge and agree that TagoBits has the right to monitor the Sites electronically from time to time and to disclose any information as necessary or appropriate to satisfy any law, regulation or other governmental request, to operate the Sites properly, or to protect itself or its users. TagoBits will not intentionally monitor or disclose any private electronic-mail message unless required by law. TagoBits reserves the right to refuse to post or to remove any information or materials, in whole or in part, that it finds, in its sole discretion, is unacceptable, undesirable, inappropriate or in violation of these Terms.</p>
<h1>Discussion Forum</h1>
<p>TagoBits includes a feature called Discussion that allows you to exchange useful and helpful information with other users of the Sites by posting information (&quot;User Posts&quot;) to a Discussion board. The following specific terms and conditions apply to your use of Discussion:</p>
<ul>
    <li>Content. You agree that TagoBits does not endorse and is not responsible for the accuracy of the content of User Posts, and will not be liable for any damages incurred as a result of the submission or use of any such content. Discussion users may post hypertext links to content hosted and maintained by third parties. TagoBits has no obligation to monitor these linked sites, and is not responsible for them. Your access to any linked sites is at your own risk, so use good judgment before you click on any link or access any linked site. Do not reveal information that you do not want to make public, such as your contact information or email address, while using Discussion. TagoBits reserves the right to monitor Discussion and the content of User Posts from time to time. Additionally, TagoBits reserves the right to edit, remove or refuse to remove User Posts in its sole discretion.
</li>
<li> License. You agree that your license to use any User Posts or any other content posted under the Discussion feature is a limited license granted pursuant to the Creative Commons Attribution/Share-Alike license (http://creativecommons.org/licenses/by-sa/3.0/). You further agree that TagoBits may, in its sole discretion, use any User Posts or any other content posted under the Discussion feature pursuant to the Creative Commons Attribution/Share-Alike license.</li>
<li>Use. You agree that TagoBits may, in its sole discretion, use the User Posts you provide to TagoBits in any way, including in future modifications of the Sites, multimedia works and/or advertising and promotional materials relating thereto. You hereby grant TagoBits a perpetual, worldwide, fully transferable, irrevocable, royalty free license to use, reproduce, modify, create derivative works from, distribute and display the User Posts in any manner and for any purpose.
</li>
<li> Conduct. You agree not to upload, post or otherwise transmit any content (including but not limited to text, links, communications, software, images, sounds, data or other information) that contains: (a) Inappropriate content, such as: profanity, objectionable material of any kind, links to websites that contain information about illegal activity, information or software that contains a virus, Trojan horse, or other harmful or disruptive component; (b) Spam, such as: commercial solicitations, chain letters, securities offerings, repetitive, pointless or irrelevant postings; (c) Attacks, such as: &quot;flaming&quot; other participants in a manner that might incite or perpetuate a conflict or argument, creating usernames to attack other participants identities, impersonating other individuals or falsely representing your identity or qualifications; (d) Postings that breach any user&apos;s privacy.


You will be required to register with TagoBits and select a password and screen name (&quot;`TagoBits User ID&quot;`). You must provide TagoBits with accurate, complete, and updated registration information. You may not (a) select or use as a TagoBits User ID a name of another person with the intent to impersonate that person; (b) use as a TagoBits User ID a name subject to any rights of a person other than you without appropriate authorization; or (c) use as a TagoBits User ID a name that is otherwise offensive, vulgar or obscene. TagoBits reserves the right to refuse registration of, or cancel a TagoBits User ID in its sole discretion. You are solely responsible for activity that occurs on your account and shall be responsible for maintaining the confidentiality of your TagoBits password. You may never use another user&apos;s account without such user&apos;s express permission. You will immediately notify TagoBits in writing of any unauthorized use of your account, or other account related security breach of which you are aware.
</li>
</ul>
<h1>Third Party Services</h1>
<p>In connection with your use of the Sites, you may be made aware of services, products, offers and promotions provided by third parties, and not by TagoBits (&quot;Third Party Services&quot;). If you decide to use any Third Party Services, you are responsible for reviewing and understanding the terms and conditions governing such Third Party Services. You agree that the third party provider, and not TagoBits, is responsible for the performance of the Third Party Services.
</p>
<h1>Third Party Websites</h1>
<p>The Sites and specifically the User Posts may contain or reference links to websites operated by third parties (&quot;Third Party Websites&quot;). Such Third Party Websites are not under the control of TagoBits. TagoBits is not responsible for the content of any Third Party Websites or any link contained in Third Party Websites. TagoBits does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third Party Websites, and the inclusion of any link to any Third Party Website on the Site is not and does not imply an affiliation, sponsorship, endorsement, approval, investigation, verification or monitoring by TagoBits of any information contained in any Third Party Website. In no event will TagoBits be responsible for the information contained in such Third Party Websites or for your use of or inability to use such Third Party Websites. Access to any Third Party Websites is at your own risk, and you acknowledge and understand that linked Third Party Websites may contain separate terms and privacy policies that apply to such Third Party Websites and are not administered by TagoBits. TagoBits is not responsible for such provisions, and expressly disclaims any liability related to any Third Party Websites.</p>
<h1>Fees for Services</h1>
<p>If you opt to use the services offered by TagoBits which require a paid subscription or other payment by you of any kind (the &quot;Services&quot;) the following terms will apply to you. Payments are paid in U.S. dollars and credit cards will be charged in U.S. dollars unless otherwise agreed to in writing by TagoBits. The Services will be deemed accepted by you upon payment of the applicable fees and/or subscription fees for such Services. Access to the Services will begin after TagoBits receives and processes all the information, including the credit card or bank account information, requested by the registration or ordering process. You must have a valid credit card or a valid debit card acceptable to TagoBits (a &quot;Card&quot;) or sufficient funds in a checking or savings account to cover an electronic debit of the fees to obtain access to the Services. The payment information you provide must be accurate, current and complete, and you agree to notify TagoBits promptly of any change in the payment information. When you subscribe and provide payment information, your Card or bank account will be debited, and will be automatically re-debited at the beginning of each applicable monthly or one-year subscription term at the then-current subscription rate to maintain access to the Services. Cancellation and renewal terms may be supplemented by program terms provided to you in writing or on the Sites for the Services you have selected.</p>
<h1>Online Payment Services</h1>
<p>Access to online banking, online payment, or any other payment services available through the Internet and for use with TagoBits Services (the &quot;Online Payment Services&quot;) is provided by your financial institution and not TagoBits. You agree not to hold TagoBits liable for any loss or damage of any sort incurred as a result of any such dealings with any services provided by your financial institution. Your access may be limited from time to time, depending on the service provided by your Internet service provider or your financial institution. You may be billed for these Online Payment Services by your financial institution, not TagoBits, and such financial institution may have its own service agreement which will govern the Online Payment Services it provides.
</p>
<h1>
Trademark Information
</h1>
<p>TagoBits, the TagoBits logo, TagoBits.net and Tagopay.com, among others, are trademarks and/or service marks of TagoBits, Inc. TagoBits reserves all rights with respect to its trademarks, service marks, logos, trade names, trade dress and other indicia (&quot;Marks&quot;). Any unauthorized use of any Mark owned by TagoBits, or any use of a Mark that is confusingly similar to, or likely to cause confusion with, a Mark owned by TagoBits, would constitute infringement of TagoBits&apos;s exclusive trademark rights.</p>
<h1>Copyright Information</h1>
<p>TagoBits&apos;s products, services, web content and related materials are owned by TagoBits, Inc., and all rights in such materials are reserved by TagoBits, Inc.</p>
<h1>Disclaimer of Warranties</h1>
<p>Your use of the Sites, including any applets, software, and content contained therein, is entirely at your own risk. THE SERVICE IS PROVIDED `&quot;AS IS,`&quot; AND TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, TAGOBITS, ITS AFFILIATES, LICENSORS, THIRD-PARTY CONTENT OR SERVICE PROVIDERS, DISTRIBUTORS, DEALERS AND SUPPLIERS (COLLECTIVELY `&quot;SUPPLIERS`&quot;) DISCLAIM ALL GUARANTEES AND WARRANTIES, WHETHER EXPRESS, IMPLIED, OR STATUTORY, REGARDING THE SITES AND RELATED MATERIALS, INCLUDING ANY WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY AND NON-INFRINGEMENT. TAGOBITS DOES NOT WARRANT OR GUARANTEE THE ACCURACY, RELIABILITY, COMPLETENESS, USEFULNESS, NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR QUALITY OF ANY CONTENT ON THE SITES, REGARDLESS OF WHO ORIGINATES THAT CONTENT. TAGOBITS DOES NOT WARRANT THAT THE SITES ARE SECURE, FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION OR THAT THE SITES WILL MEET YOUR REQUIREMENTS. YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOU BEAR ALL RISKS ASSOCIATED WITH USING OR RELYING ON THAT CONTENT. TAGOBITS IS NOT LIABLE OR RESPONSIBLE IN ANY WAY FOR ANY CONTENT POSTED ON OR LINKED FROM THE SITES, INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN CONTENT, OR FOR ANY LOSSES OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF OR RELIANCE ON ANY CONTENT.</p>
<h1>Limitation of Liability</h1>
<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL TAGOBITS ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, CONTENT PROVIDERS, ADVERTISERS OR USERS BE LIABLE FOR ANY LOST PROFITS, INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES OR FOR DAMAGES RELATING TO TELECOMMUNICATION FAILURES, LOSS, CORRUPTION, SECURITY OR THEFT OF DATA, VIRUSES, BUGS, SPYWARE, TROJAN HORSE OR THE LIKE, REGARDLESS OF THE SOURCE OF ORIGINATION, OR FOR ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, TRANSMITTED OR OTHERWISE MADE AVAILABLE AT OR THROUGH THE SITES, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE. YOU SPECIFICALLY RELEASE TAGOBITS FROM ALL LIABILITY FOR ANY USER CONTENT OR CONDUCT. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Sites or the Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred. THE LIMITATIONS OF LIABILITY SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN TAGOBITS AND YOU. TAGOBITS WOULD NOT HAVE BEEN ABLE TO PROVIDE THE SERVICES OFFERED ON THE SITES WITHOUT SUCH LIMITATIONS.</p>
<h1>Termination, Amendment and Indemnification</h1>
<p>Your privilege to use or access the Sites may be terminated by TagoBits immediately and without notice if you fail to comply with any term or condition of the Terms. Upon such termination, you must immediately cease accessing or using the Sites and agree not to re-register or otherwise make use of the Sites. Furthermore, you acknowledge that TagoBits reserves the right to take action, in its sole discretion, to block, nullify or deny your ability to access the Sites.
<br />
<br />
TagoBits reserves the right, in its sole discretion, at any time and from time to time to change, modify or discontinue, temporarily or permanently, the Sites and/or the Services, or any part thereof. TagoBits shall not be liable to you or other third party for any such modification, suspension or discontinuance except as expressly provided herein.
<br />
<br />
You agree to defend, indemnify and hold TagoBits and its officers, directors, employees, agents, affiliates, partners, content providers, advertisers and users harmless from any and all claims, liabilities, costs and expenses, including reasonable attorneys&apos; fees, arising in any way from your use of the Sites or the placement or transmission of any message, information or other materials through the Sites by you or users of your account or related to any violation of these Terms by you or users of your account.
</p>
<h1>Miscellaneous</h1>
<p>Except as expressly set forth herein, the Terms are a complete statement of the agreement between you and TagoBits. In the event of a conflict between the Terms of Service and the Privacy Policy, the Terms of Service shall prevail. The suppliers, agents, distributors, dealers, and employees of TagoBits are not authorized to make modifications to the Terms, or to make any additional representations, commitments or warranties binding on TagoBits. Any waiver of the Terms by TagoBits must be in a writing signed by an authorized officer of TagoBits and expressly referencing the applicable provisions of the Terms. If any provision of the Terms is invalid or unenforceable under applicable law, then it shall be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law, and the remaining provisions will continue in full force and effect. The Terms will be governed by Wyoming law as applied to agreements entered into and to be performed entirely within Wyoming, without regard to choice of law or conflicts of law principles that would require application of law of a different jurisdiction. You expressly agree to the exclusive jurisdiction, forum and venue of the state or federal courts located in Cheyenne,  Laramie County, Wyoming,  for any claim or action arising out of or relating to the Terms or your use of the Sites, and you further agree to and submit to such forum and venue, and to the exercise of personal jurisdiction over you of such courts. Headings are included for convenience only, and shall not be considered in interpreting these Terms. The Terms do not limit any rights or remedies that TagoBits may have under trade secret, copyright, patent or other laws.</p>
            </div>
        </div>
        </div>
    );
};

export default Terms;
