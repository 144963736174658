import React from "react";
import tagocash_logo from 'assets/images/tagocash_logo.png'
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-xl position-sticky top-0 shadow-sm bg-white z-3" style={{ lineHeight: '3rem' }}>
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          <img src={tagocash_logo} width={"218px"} height={"43px"} alt="" />
        </NavLink>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          style={{ textAlign: "center" }}
          className="collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav m-auto">
            <li className="nav-item me-md-5">
              <NavLink
                className="nav-link fs-6 fw-normal text-dark"
                aria-current="page"
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item me-md-5">
              <NavLink className="nav-link fs-6 fw-normal text-dark" to="/blog">
                Blogs
              </NavLink>
            </li>
            <li className="nav-item me-md-5">
              <NavLink className="nav-link fs-6 fw-normal text-dark" to="/contact">
                Help & Support
              </NavLink>
            </li>
            <li className="nav-item me-md-5">
              <NavLink className="nav-link fs-6 fw-normal text-dark" to="/faq">
                FAQ&apos;s
              </NavLink>
            </li>
          </ul>

          <NavLink
            to="/signup"
            className="my-2 me-3 btn button_color ms-md-0 ms-3 rounded-5 px-xl-5 py-xl-2 px-lg-3 py-lg-2 px-5 py-2 border-0 outline-0"
          >
            <div className="fs-6 text-decoration-none text-white fw-normal">
              Sign Up
            </div>
          </NavLink>
          <br />
          <NavLink
            to="/wallet"
            className="border btn border-primary rounded-5 px-xl-4 py-xl-2 px-lg-3 py-lg-2 px-4 py-2"
          >
            <div className="fs-6 text-primary text-decoration-none fw-normal">
              Open TagoWallet{" "}
            </div>
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
