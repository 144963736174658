import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from 'dompurify';
import { Lottie } from '@crello/react-lottie';
import Clock from '../../assets/images/lottieFiles/clock.json';
import Coin from '../../assets/images/lottieFiles/coin.json';
import Auth from '../../assets/images/lottieFiles/auth.json';
import Lock from '../../assets/images/lottieFiles/lock.json';
import Eye from '../../assets/images/lottieFiles/eye.json';
import { Base_URL } from "constant/Constant";

const FasstSection = () => {
  const [homePage, setHomePage] = useState({}); // Use object instead of array

  useEffect(() => {
    const fetchHomePage = async () => {
      try {
        const res = await axios.get(`${Base_URL}/home_page`);
        setHomePage(res.data[0]); // Assuming you're expecting one item
        console.log(res, ">>>>>>>>");
      } catch (err) {
        console.log(err);
      }
    };
    fetchHomePage();
  }, []);

  const clockOptions = {
    loop: true,
    autoplay: true,
    animationData: Clock,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const coinOptions = {
    loop: true,
    autoplay: true,
    animationData: Coin,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const authOptions = {
    loop: true,
    autoplay: true,
    animationData: Auth,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const eyeOptions = {
    loop: true,
    autoplay: true,
    animationData: Eye,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const lockOptions = {
    loop: true,
    autoplay: true,
    animationData: Lock,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  return (
    <div className="hero_section">
      <div className="container">
        <div className="d-flex flex-md-row flex-column-reverse align-items-center gap-3">
          <div>
            <img
              src={`${Base_URL}/images/${homePage.cover}`}
              width={"799px"}
              height={"416px"}
              alt={`Blog Cover image`}
              className="fasst_mobile"
            />
          </div>

          <div>
            <div className="d-flex align-items-center gap-2"></div>
          </div>
        </div>

        <div className="pb-5 margin_top">
          <div className="d-md-flex justify-content-center align-items-center">
            <div className="wrap_mobile">
              <h3 className="cash_king_title fasst_title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.title2) }} />
            </div>
          </div>

          <div className="d-md-flex fasst_tab mt-5">
            <div className="d-flex gap-2 mb-xl-0 mb-md-4">
              <div className="ms-2 ms-md-0 me-md-5 fasst_feature fast_feature"
                style={{
                  backgroundColor: "whitesmoke",
                  padding: "0.7em 1.3em",
                  width: "17.5ch",
                  position: "relative",
                  border: "0.9px solid #afcce4f0"
                }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    background: "#afcce4f0",
                    padding: "1.5px",
                    borderRadius: "7px",
                    width: '70px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <Lottie
                      config={{ animationData: Clock, ...clockOptions }}
                      height={50}
                      width={50}
                    />
                  </div>
                </div>

                <div className="text-center pt-2">
                  <h3
                    className="fasst_subtitle footer_title"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc5) }}
                  >
                  </h3>
                </div>
                <p
                  className="fasst_feature_description fasst_fast_text_width pt-1"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par1) }}
                />
              </div>


              <div className="ms-md-3 me-md-5 fasst_feature fast_feature" style={{
                backgroundColor: "whitesmoke",
                padding: "0.7em 1.3em",
                width: "17.5ch",
                position: "relative",
                border: "0.9px solid #afcce4f0"

              }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    background: "#afcce4f0",
                    padding: "1.5px",
                    borderRadius: "7px",
                    width: '70px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <Lottie
                      config={{ animationData: Coin, ...coinOptions }}
                      height={50}
                      width={50}
                    />
                  </div>
                </div>

                <div className="text-center pt-2">
                  <h3
                    className="fasst_subtitle footer_title"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc6) }}
                  />
                </div>
                <p
                  className="fasst_feature_description fasst_affordable_text_width pt-1 pixel-styles fasst-text-line"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par2) }}
                />
              </div>
            </div>
            <div className="d-flex gap-2 mt-4 mt-md-0 mb-xl-0 mb-md-4">
              <div className="ms-2 me-md-5 fasst_feature fast_feature" style={{
                backgroundColor: "whitesmoke",
                padding: "0.7em 1.3em",
                width: "17.5ch",
                position: "relative",
                border: "0.9px solid #afcce4f0"
              }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    background: "#afcce4f0",
                    padding: "1.5px",
                    borderRadius: "7px",
                    width: '70px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <Lottie
                      config={{ animationData: Auth, ...authOptions }}
                      height={50}
                      width={50}
                    />

                  </div>
                </div>

                <div className="text-center pt-2">
                  <h3
                    className="fasst_subtitle footer_title"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc7) }}
                  />
                </div>
                <p
                  className="fasst_feature_description fasst_safe_text_width pt-2"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par3) }}
                />
              </div>

              <div className="ms-md-2 me-md-5 fasst_feature fast_feature" style={{
                backgroundColor: "whitesmoke",
                padding: "0.7em 1.3em",
                width: "17.5ch",
                position: 'relative',
                border: "0.9px solid #afcce4f0"
              }}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div style={{
                    background: "#afcce4f0",
                    padding: "1.5px",
                    borderRadius: "7px",
                    width: '70px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <Lottie
                      config={{ animationData: Lock, ...lockOptions }}
                      height={50}
                      width={50}
                    />
                  </div>
                </div>

                <div className="text-center pt-2">
                  <h3
                    className="fasst_subtitle footer_title"
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc8) }}
                  />
                </div>
                <p
                  className="fasst_feature_description fasst_secure_text_width pt-1"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par4) }}
                />
              </div>
            </div>
            <div className="ms-md-3 ms-2 me-md-5 fast_feature fast-last-style" style={{
              backgroundColor: "whitesmoke",
              padding: "0.7em 1.3em",
              width: "19ch",
              position: "relative",
              border: "0.9px solid #afcce4f0"
            }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{
                  background: "#afcce4f0",
                  padding: "1.5px",
                  borderRadius: "7px",
                  width: '70px',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <Lottie
                    config={{ animationData: Eye, ...eyeOptions }}
                    height={50}
                    width={50}
                  />
                </div>
              </div>

              <div className="text-center pt-2">
                <h3
                  className="fasst_subtitle footer_title"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.desc9) }}
                />
              </div>
              <p
                className="fasst_feature_description fasst_secure_text_width pt-1 wdth-style"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(homePage.par5) }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FasstSection;