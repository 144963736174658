import React, { useState, useEffect } from 'react'
import axios from "axios";
import { NavLink } from 'react-router-dom';
import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Card,
    Center,
    Container,
    Divider,
    Flex,
    HStack,
    Heading,
    Highlight,
    Image,
    Link,
    ListItem,
    OrderedList,
    Text,
    UnorderedList,
    useDisclosure,
} from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import CookiesModal from 'components/cookies/cookies'
import {
    AppleIcon,
    ApplePay,
    FB,
    GooglePay,
    Instagram,
    LinkedIn,
    Logo,
    PlayIcon,
    Twitter,

} from 'assets/icons'
import stellarIcon from 'assets/icons/stellarIcon.png'
import circles from 'assets/images/circles.png'

function Footer() {
    return (
        <>
            <CookiesModal />
            <Box
                as="footer"
                textAlign="center"
                bg="#0A2540"
                pt={{ base: '40px', md: '96px' }}
                minHeight="75vh"
            >
                <div>
                    <Container maxW="1280px">
                        <Text
                            m="25px auto"
                            fontSize={{ base: '14px', md: '15px' }}
                            lineHeight={{ base: '1.5rem', md: '2rem' }}
                            color="#918f8e"
                        >
                            TagoCash is not a bank nor a payment service provider. TagoCash does not provide any exchange services between virtual currencies and fiat currencies. TagoCash does not provide custodial wallet services. TagoCash is not a crypto service provider. TagoCash does not insure deposits.
                        </Text>

                        <HStack justify="center" mb="60px">
                            <Text variant="white" fontSize="24px" fontWeight="900">
                                TagoCash
                            </Text>
                            <Flex gap="23px" borderLeft="1px" pl="1rem">
                                <FB />
                                <Twitter />
                                <LinkedIn />
                                <Instagram />
                            </Flex>
                        </HStack>
                        <HStack
                            justify="center"
                            gap={{ base: '20px', md: 'unset' }}
                            flexWrap="wrap"
                            mt="35px"
                            align="flex-start"
                        >
                            {' '}
                            <Link
                                color="#fff"
                                fontSize="18px"
                                minW={{ base: '200px', md: '100px' }}
                                margin="0"
                            >
                                <NavLink to="/">
                                    Home
                                </NavLink>
                            </Link>{' '}
                            <Divider
                                orientation="vertical"
                                h="26px"
                                borderColor="#fff"
                                display={{ base: 'none', md: 'block' }}
                            />
                            <Link
                                color="#fff"
                                fontSize="18px"
                                cursor="pointer"
                                margin="0"
                                minW={{ base: '200px', md: '175px' }}
                            >
                                <NavLink to='/terms'>
                                    Terms of Service
                                </NavLink>
                            </Link>{' '}


                            <Divider
                                orientation="vertical"
                                h="26px"
                                borderColor="#fff"
                                display={{ base: 'none', md: 'block' }}
                            />{' '}

                            <Link
                                color="#fff"
                                fontSize="18px"
                                cursor="pointer"
                                margin="0"
                                minW={{ base: '200px', md: '100px' }}
                            >
                                <NavLink to="/privacy">
                                    Privacy
                                </NavLink>
                            </Link>


                            <Divider
                                orientation="vertical"
                                h="26px"
                                borderColor="#fff"
                                display={{ base: 'none', md: 'block' }}
                            />{' '}

                            <Link color="#fff" fontSize="18px" minW="175px" margin="0">
                                <NavLink to="/fees">
                                    About our Fees
                                </NavLink>
                            </Link>

                            <Divider
                                orientation="vertical"
                                h="26px"
                                borderColor="#fff"
                                display={{ base: 'none', md: 'block' }}
                            />
                            <Link
                                color="#fff"
                                fontSize="18px"
                                margin="0"
                                minW={{ base: '200px', md: '120px' }}
                            >
                                <NavLink to="/contact">
                                    Contact Us
                                </NavLink>
                            </Link>
                        </HStack>
                        <Text m="25px auto" fontSize="14px" maxW="660px">
                            TagoBits, Inc. is an fintech company operating at the frontier of the digital and the fiat currency. TagoCash is a global community project supported by Stellar. Its mission is to provide financial equinimity to people all over the world.
                        </Text>
                    </Container>
                    <Text variant="white" fontSize="16px">
                        TagoCash is ©{new Date().getFullYear()} Trademark Registered
                        by TagoBits, Inc.
                    </Text>
                </div >

            </Box>

        </>
    );
}

export default Footer;
