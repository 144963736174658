import React from 'react'
import './thankyou.css'


const ThankYou = () => {
    return (
        <section className="thankyou">
        <div className="thankyou-content">
  <div className="thankyou-wrapper-1">
    <div className="thankyou-wrapper-2">
      <h1>Thank You !</h1>
      <p>Thank You for subscribing to our news letter.  </p>
      <p>We will notify You on future Updates !</p>
      <a href="/">
      <button className="thankyou-go-home">
      Go back home
      </button>
      </a>
    </div>
    <div className="thankyou-footer-like">
      
    </div>
</div>
</div>
        </section>
    )
}

export default ThankYou