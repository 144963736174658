import React, { useEffect, useState } from "react";
import blue_background from "../../../assets/images/blue_background.png";
import Lottie from "react-lottie";
import axios from "axios"
import contactUsLottie from "../../../assets/images/lottieFiles/contactUs.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ContactUs = () => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const [isNameValid, setIsNameValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [country, setCountry] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isMessageValid, setIsMessageValid] = useState(true);
  const [isCheckedValid, setIsCheckedValid] = useState(true);

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [isCheckedError, setIsCheckedError] = useState("");

  useEffect(() => {
    axios.get('https://ipapi.co/json/')
      .then(response => {
        const country = response.data.country.toLowerCase();
        setCountry(country);
      })
      .catch(error => {
        console.error("Error fetching user's country", error);
      });
  }, []);


  const handleFirstNameChange = (event) => {
    const newFirstName = event.target.value;
    setFirstName(newFirstName);
    setIsNameValid(newFirstName.trim() !== "");
    setNameError(newFirstName.trim() !== "" ? "" : "First Name cannot be empty");
  };

  const handleMiddleNameChange = (event) => {
    const newMiddleName = event.target.value;
    setMiddleName(newMiddleName);
    setIsNameValid(newMiddleName.trim() !== "");
    setNameError(newMiddleName.trim() !== "" ? "" : "Middle Name cannot be empty");
  };

  const handleLastNameChange = (event) => {
    const newLastName = event.target.value;
    setLastName(newLastName);
    setIsNameValid(newLastName.trim() !== "");
    setNameError(newLastName.trim() !== "" ? "" : "Last Name cannot be empty");
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(newEmail));
    setEmailError(emailRegex.test(newEmail) ? "" : "Invalid email format");
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
  };

  const handleMessageChange = (event) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
    setIsMessageValid(newMessage.trim() !== "");
    setMessageError(newMessage.trim() !== "" ? "" : "Message cannot be empty");
  };

  const handleCheckboxChange = () => {
    const newIsChecked = !isChecked;
    setIsChecked(newIsChecked);
    setIsCheckedValid(newIsChecked);
    setIsCheckedError(newIsChecked ? "" : "You must agree to the terms");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const fullName = `${firstName} ${middleName} ${lastName}`;

    setIsNameValid(fullName.trim() !== "");
    setIsEmailValid(email.trim() !== "");
    setIsPhoneNumberValid(phoneNumber.trim() !== "");
    setIsMessageValid(message.trim() !== "");
    setIsCheckedValid(isChecked);

    setNameError(fullName.trim() !== "" ? "" : "Name cannot be empty");
    setEmailError(email.trim() !== "" ? "" : "Email cannot be empty");
    setPhoneNumberError(
      phoneNumber.trim() !== "" ? "" : "Phone cannot be empty"
    );
    setMessageError(message.trim() !== "" ? "" : "Message cannot be empty");
    setIsCheckedError(isChecked ? "" : "You must agree to the terms");

    if (
      isNameValid &&
      isEmailValid &&
      isPhoneNumberValid &&
      isMessageValid &&
      isCheckedValid
    ) {
      console.log("Form submitted:", {
        firstName,
        middleName,
        lastName,
        email,
        phoneNumber,
        message,
        isChecked,
      });

      setFirstName("");
      setMiddleName("");
      setLastName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");
      setIsChecked(false);
    }
  };

  const contactOptions = {
    loop: true,
    autoplay: true,
    animationData: contactUsLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="contact_section position-relative">
      <div className="d-block d-md-none blue_background">
        <img
          className="position-absolute top-0 left-0"
          src={blue_background}
          width={"90%"}
          height={"300px"}
          alt=""
        />
      </div>
      <div className="container position-relative pb-5">
        <div className="d-md-flex align-items-center py-md-5 pb-3">
          <a href="/" className="text-decoration-none text-black">
            <p className="d-block d-md-none pt-md-4 pt-3">&lt; Back</p>
          </a>
          <div className="left_sided_contact margin_top pb-md-5 contact_block">
            <div id="contact_lottie" className="d-none d-md-block">
              <Lottie options={contactOptions} height={339} width={373} />
            </div>
          </div>

          <div className="form_section margin_top">
            <div>
              <h3 className="hero_title contact_title mb-md-1">Contact us</h3>
              <p className="sub_text contact_subtext mb-4">
                We welcome your questions or comments
              </p>
            </div>
            <div className="mb-4">
              <input
                type="text"
                name="name"
                value={firstName}
                onChange={handleFirstNameChange}
                className="form-control rounded-5 px-3 py-2 border-0"
                id="exampleFormControlInput1"
                placeholder="First Name*"
              />
              {!isNameValid && <p className="text-danger pt-2">{nameError}</p>}
            </div>
            <div className="mb-4">
              <input
                type="text"
                name="name"
                value={middleName}
                onChange={handleMiddleNameChange}
                className="form-control rounded-5 px-3 py-2 border-0"
                id="exampleFormControlInput1"
                placeholder="Middle Name*"
              />
              {!isNameValid && <p className="text-danger pt-2">{nameError}</p>}
            </div>
            <div className="mb-4">
              <input
                type="text"
                name="name"
                value={lastName}
                onChange={handleLastNameChange}
                className="form-control rounded-5 px-3 py-2 border-0"
                id="exampleFormControlInput1"
                placeholder="Last Name*"
              />
              {!isNameValid && <p className="text-danger pt-2">{nameError}</p>}
            </div>
            <div className="mb-4">
              <input
                type="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                className="form-control rounded-5 px-3 py-2 border-0"
                id="exampleFormControlInput2"
                placeholder="Email*"
              />
              {!isEmailValid && (
                <p className="text-danger pt-2">{emailError}</p>
              )}
            </div>

            <div>
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={handlePhoneChange}
                placeholder="phone number"
              />
              {!isPhoneNumberValid && (
                <p className="text-danger pt-2">{phoneNumberError}</p>
              )}
            </div>
            <div className="my-4">
              <textarea
                name="textArea"
                value={message}
                onChange={handleMessageChange}
                className="form-control rounded-5 px-3 py-3 border-0"
                id="exampleFormControlTextarea4"
                rows="3"
                placeholder="Message*"
              ></textarea>
              {!isMessageValid && (
                <p className="text-danger pt-2">{messageError}</p>
              )}
            </div>
            <div>
              <div className="form-check d-flex align-items-center">
                <input
                  className="form-check-input rounded-0 p-2 me-3 pointer"
                  type="checkbox"
                  name="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  id="flexCheckDefault"
                />
                <label
                  className="form-check-label contact_subtext fs-6"
                  htmlFor="flexCheckDefault"
                >
                  Please Send me <strong>Updates</strong> on Tagocash.
                </label>
              </div>
              {!isCheckedValid && (
                <p className="text-danger pt-2">{isCheckedError}</p>
              )}
            </div>
            <div className="mb-5 mt-4">
              <button
                onClick={handleSubmit}
                disabled={
                  !isNameValid ||
                  !isEmailValid ||
                  !isPhoneNumberValid ||
                  !isMessageValid ||
                  !isCheckedValid
                }
                type="button"
                className="btn button_color text-white rounded-5 px-5 py-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;