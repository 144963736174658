import {
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Card,
    Center,
    Container,
    Divider,
    Flex,
    HStack,
    Heading,
    Highlight,
    Image,
    Link,
    ListItem,
    OrderedList,
    Text,
    UnorderedList,
    useDisclosure,
} from '@chakra-ui/react'
import { Safe } from '../../assets/icons'
import React, { useState, useEffect } from 'react';
import { FiShield } from 'react-icons/fi'; // Import the security icon from Chakra UI
import PrivcyModal from '../modal'
import axios from 'axios';
import ipinfo from 'ipinfo';
import { isMobile, isTablet, isDesktop, isBrowser } from 'react-device-detect';

export default function CookiesModal() {
    const [isModalOpen, setIsModalOpen] = useState('');

    useEffect(() => {
        const acceptedCookie = document.cookie.includes('acceptedCookie=true');
        if (acceptedCookie) {
            setIsModalOpen('none');
        } else {
            setIsModalOpen('none');
        }
    }, []);

    const closeModal = async () => {
        setIsModalOpen('none');

        try {
            await axios.post('https://backend.tagocash.com/increment-cookie-count');
            const cookieCountResponse = await axios.get('https://backend.tagocash.com/get-cookie-count');

            document.cookie = 'acceptedCookie=true; max-age=31536000; path=/';

            const ipResponse = await axios.get('https://ipinfo.io?token=977e06d01e2778');
            const ipDetails = ipResponse.data;
            const timestamp = new Date();

            const details = {
                ip: ipDetails.ip,
                country: ipDetails.country,
                city: ipDetails.city,
                latitude: ipDetails.loc.split(',')[0],
                longitude: ipDetails.loc.split(',')[1],
                browser: navigator.userAgent,
                device: isMobile ? 'Mobile' : isTablet ? 'Tablet' : isDesktop ? 'Desktop' : isBrowser ? 'Browser' : 'Other',
                timestamp: timestamp.toISOString(),
            };

            const backendUrl = 'https://backend.tagocash.com/collectDetails';
            const postResponse = await axios.post(backendUrl, details);
            console.log('User details collected and sent to the backend:', postResponse.data);
        } catch (error) {
            console.error('Error collecting and sending user details:', error);
        }
    };
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0)
    const { isOpen, onOpen, onClose } = useDisclosure()
  

    const settings = {
        dots: false,
        arrows: false,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        afterChange: (index: number) => {
            setActiveSlideIndex(index)
        },
    }

    return (
        <>
            <div className="cookies-content" style={{ display: `none` }}>
                {/* DESK TOP */}
                <div className="desktop">
                    <p className="desktopMode">
                        <span>
                            <h1 style={{ color: 'aqua'}}> Want to help us improve your experience, Accept Cookies.</h1>

                        </span>
                        {/*<br />*/}
                        Enabling these optional cookies helps us provide you with a better, more personalized experience, like your
                        preferred language. To learn more, see our{' '}
                        <Link color="teal" href="/privacy">
                            Privacy Policy
                        </Link>
                        . By using the service, you agree to the{' '}
                        <Link color="teal" href="/terms">
                            Terms of Service
                        </Link>
                        .
                    </p>
                    <Button colorScheme="teal" className="btn-accept" variant="outline" onClick={closeModal}>
                        Accept
                    </Button>
                </div>
                {/* MOBILE */}
                <div className="mobile">
                    <div className='safe'>
                        <Safe />
                    </div>
                    <p className="mobileMode" style={{ lineHeight: '2.3' }}>
                        <span>
                            <h1 style={{ color: 'aqua' }}> Want to help us improve your experience, Accept Cookies.</h1>

                        </span>
                        {/*<br />*/}
                        Enabling these optional cookies helps us provide you with a better, more personalized experience, like your
                        preferred language. To learn more, see our{' '}
                        <Link color="teal" href="/privacy">
                            Privacy Policy
                        </Link>
                        . By using the service, you agree to the{' '}
                        <Link color="teal"  href="/terms">
                            Terms of Service
                        </Link>
                        .
                    </p>
                    <br />
                    <Button className="btn-accept-mobile" onClick={closeModal}>
                        Accept Cookies
                    </Button>
                </div>
            </div>
           

        </>
    );
}

