import React, { useState } from 'react'
import './process.css'
import Lottie from 'react-lottie'
import recipientLottie from "../../../assets/images/lottieFiles/recipient.json";
import amountLottie from "../../../assets/images/lottieFiles/amount.json";
import confirmPayLottie from "../../../assets/images/lottieFiles/confirm_pay.json";

export const PaymentProcess = () => {

    const [step, setStep] = useState(1);

    const recipientOptions = {
        loop: true,
        autoplay: true,
        animationData: recipientLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const amountOptions = {
        loop: true,
        autoplay: true,
        animationData: amountLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const confirmPayOptions = {
        loop: true,
        autoplay: true,
        animationData: confirmPayLottie,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };


    return (
        <>
            <div className="container-fluid pt-5 pb-5" style={{background: "#052A4F"}}>
                <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <p className='paying_style'>Paying Someone Is Easier & Cheaper</p>
                        <p className="par-process">With TagoCash, paying someone is as easy as paying with cash.</p>

                        <p className="par-process">Complete your payment in three simple steps:</p>
                        <div className='paying_pading d-flex flex-column'>
                            <button className='paying_btn' onClick={() => setStep(1)}>
                                <p className='paying_content' style={{ color: step === 1 ? '#0581F8' : '#FFFFFF33' }}>1</p>
                                <p className='paying_content_one' style={{ color: step === 1 ? '#0581F8' : 'white' }}>Choose Recipient</p>
                            </button>
                            {step === 1 && (
                                <p className='paying_content_two'>Find your partner on TagoCash app via their phone or email.</p>
                            )}
                            <button className='paying_btn' onClick={() => setStep(2)}>
                                <p className='paying_content' style={{ color: step === 2 ? '#0581F8' : '#FFFFFF33' }}>2</p>
                                <p className='paying_content_one' style={{ color: step === 2 ? '#0581F8' : 'white' }}>Enter Amount</p>
                            </button>
                            {step === 2 && (
                                <p className='paying_content_two'>Enter the amount to send from your wallet balance.</p>
                            )}
                            <button className='paying_btn' onClick={() => setStep(3)}>
                                <p className='paying_content' style={{ color: step === 3 ? '#0581F8' : '#FFFFFF33' }}>3</p>
                                <p className='paying_content_one' style={{ color: step === 3 ? '#0581F8' : 'white' }}>Confirm and Pay</p>
                            </button>
                            {step === 3 && (
                                <p className='paying_content_two'>Money is received by your partner in less than 10 seconds!</p>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 paying_col">
                        <div className="lottie_single">
                            {step === 1 && <Lottie options={recipientOptions} />}
                            {step === 2 && <Lottie options={amountOptions} />}
                            {step === 3 && <Lottie options={confirmPayOptions} />}
                        </div>
                    </div>
                </div>
                </div>
                
            </div>
        </>
    )
}
