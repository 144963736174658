import React, { useState, useEffect } from 'react'
import './better.css'
import Group from '../../../assets/images/tagocash_different.png'
import Hand from '../../../assets/images/Hand-phone-tago.png'
import Star from '../../../assets/images/image 40.png'
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropup } from "react-icons/io";
import PropTypes from 'prop-types';

// Define the AccordionItem component before using it
const AccordionItem = ({ isOpen, toggleAccordion, title, detail }) => {
    return (
        <div className='accordion_body ps-2 pe-2 pt-2 pb-2' style={{ border: isOpen ? '1px solid #0581F8' : '1px solid #8F8F8F', cursor: "pointer" }} onClick={toggleAccordion}>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    {isOpen ? <img src={""} alt="" style={{ marginRight: '32px' }} /> : <img src={Star} alt="" style={{ marginRight: '10px' }} />}

                    <p className='accordion_style' style={{ color: isOpen ? '#0581F8' : '#2C2C2C' }}>{title}</p>
                </div>
                <div style={{ cursor: 'pointer' }}>
                    {isOpen ? <IoMdArrowDropup style={{ fontSize: "20px" }} /> : <IoMdArrowDropdown style={{ fontSize: "20px" }} />}
                </div>
            </div>
            {isOpen && (
                <div className='accordion_detail open'>
                    <p className='m-0 pe-4'>{detail}</p>
                </div>
            )}
        </div>
    );
};



export const Better = () => {

    const [accordionItems, setAccordionItems] = useState([
        { isOpen: true, title: "Tagocash is globally available", detail: "TagoCash is the cash of where you happen to be Instantly exchangeable for local consumption. Spend it in dollars, Euro, Pesos, or any of 65+ different currencies." },
        { isOpen: false, title: "TagoCash is very safe", detail: "TagoCash can not be stolen. It is only available to you and requires your biometrics to access. Your TagoCash is not stored or controlled by the Tago Platform. It is only referenced on the wallet controlled by you." },
        { isOpen: false, title: "TagoCash is private", detail: "Using TagoCash protects your privacy. You do not need to share your address or bank or credit/debit card details with anyone. When you want to give TagoCash to someone, you only need their mobile number and email address." }
    ]);

    const toggleAccordion = (index) => {
        setAccordionItems(prevItems =>
            prevItems.map((item, i) => ({
                ...item,
                isOpen: i === index ? !item.isOpen : false // Toggle the clicked item and close others
            }))
        );
    };


    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <div className="row pt-5">
                        <div className="col">
                            <h2 className='tago_cashies_style'>Tago<span style={{ color: "#2E3293" }}>Cash </span>
                            Is Better Than Cash!
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 img_div">
                            <img src={Hand} alt="" className='tago_img' />
                        </div>
                        <div className="col-lg-6 d-flex align-items-center justify-content-center tago_accordion">
                            <div className='d-flex gap-4 flex-column'>
                                {accordionItems.map((item, index) => (
                                    <AccordionItem
                                        key={index}
                                        isOpen={item.isOpen}
                                        toggleAccordion={() => toggleAccordion(index)}
                                        title={item.title}
                                        detail={item.detail}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

// PropTypes for AccordionItem component
AccordionItem.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleAccordion: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    detail: PropTypes.string.isRequired,
};
